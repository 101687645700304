import React from 'react';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { Divider, useMediaQuery } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PaymentsIcon from '@mui/icons-material/Payments';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArticleIcon from '@mui/icons-material/Article';
import { APPBAR_HEIGHT, DRAWER_WIDTH } from '../../configs/Constants';
import RoutePaths from '../../configs/Routes';
import NavItem from './NavItem';
import {
  handleCloseMainNavbarDrawer,
  handleSidebarDrawerToggle,
} from '../../store/actions/drawerActions';

function Sidebar() {
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Drawer
      open
      sx={{
        minWidth: lgUp ? DRAWER_WIDTH : '100%',

        [`& .MuiDrawer-paper`]: {
          minWidth: lgUp ? DRAWER_WIDTH : '100%',
          background: '#fff',
          marginTop: lgUp ? `${APPBAR_HEIGHT}px` : '0x',
          paddingTop: lgUp ? 'auto' : '20px',
          borderRight: 'none',
          zIndex: lgUp ? 1 : 99999999999,
          minHeight: lgUp ? `calc(100vh - ${APPBAR_HEIGHT * 6}px)` : '100vh',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      {!lgUp && (
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            handleSidebarDrawerToggle();
            handleCloseMainNavbarDrawer();
          }}
          sx={{ alignSelf: 'end', mr: 2, mb: 2 }}
        >
          <HighlightOffIcon />
        </IconButton>
      )}

      <nav>
        <List disablePadding>
          <li>
            <NavItem to={RoutePaths.HOME}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Overview" />
            </NavItem>
          </li>
          <li>
            <NavItem to={RoutePaths.SALES}>
              <ListItemIcon>
                <MonetizationOnIcon />
              </ListItemIcon>
              <ListItemText primary="Sales" />
            </NavItem>
          </li>
          <li>
            <NavItem to={RoutePaths.PAYMENTS}>
              <ListItemIcon>
                <PaymentsIcon />
              </ListItemIcon>
              <ListItemText primary="Payments" />
            </NavItem>
          </li>
          <li>
            <NavItem to={RoutePaths.CREATIVES}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Creatives" />
            </NavItem>
          </li>
          <li>
            <NavItem to={RoutePaths.MANAGE_PROFILE}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Profile" />
            </NavItem>
          </li>
          {/* {!lgUp && (
            <>
              <li>
                <NavItem to={RoutePaths.ABOUT_US}>
                  <ListItemIcon>
                    <BusinessIcon />
                  </ListItemIcon>
                  <ListItemText primary="About Us" />
                </NavItem>
              </li>
              <li>
                <NavItem to={RoutePaths.BLOG}>
                  <ListItemIcon>
                    <LibraryBooksIcon />
                  </ListItemIcon>
                  <ListItemText primary="Blogs" />
                </NavItem>
              </li>
            </>
          )} */}
          <Divider variant="middle" sx={{ my: 1 }} />
          <li>
            <NavItem to={RoutePaths.LOGOUT}>
              <ListItemIcon>
                <ExitToAppOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </NavItem>
          </li>
        </List>
      </nav>
    </Drawer>
  );
}

export default Sidebar;
