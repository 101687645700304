import React from 'react';
import { Link, Container, Typography, Divider, Button, TextField, Grid, Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Validations from '../../utils/Validations';
import pageBGsm from '../../assets/images/auth_bg_sm.png';
import LogoImg from '../../assets/images/logo_blue.png';
import ResetPasswordPageBG from '../../assets/images/reset_pswd_bg.png';
import RoutePaths from '../../configs/Routes';
import AuthStyles from '../../styles/views/Auth';
import { showErrorToastr, showSuccessToastr } from '../../store/actions/toastrActions';
import { sendPasswordReset } from '../../services/Auth';
import GoogleLogo from '../../components/common/GoogleLogo';

const useStyles = makeStyles(AuthStyles);

const ResetPassword = () => {
  const classes = useStyles();
  //  const dispatch = useDispatch();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { control, handleSubmit } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const toSubmitData = {
        ...data,
      };
      const user = await sendPasswordReset(toSubmitData.email);

      if (user.success) {
        showSuccessToastr(user?.message);
        navigate(RoutePaths.LOGIN);
      } else {
        showErrorToastr(user?.message || 'Something went wrong.');
        navigate(RoutePaths.RESET_PASSWORD);
      }
    } catch ({ response }) {
      showErrorToastr(typeof response === 'string' ? response : 'Something Went Wrong!');
    }
  };

  return (
    <Grid container component="main" spacing={0} sx={{ alignItems: mdUp ? 'auto' : 'center' }}>
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={7}
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundImage: mdUp ? `url(${ResetPasswordPageBG})` : `url(${pageBGsm})`,
          minHeight: !mdUp ? 300 : 'auto',
        }}
        className={classes.formImg}
      >
        {!mdUp && (
          <Box
            className={classes.logoImg}
            sx={{
              m: 0,
              backgroundImage: `url(${LogoImg})`,
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={5}>
        <Container maxWidth="sm">
          <Box className={classes.formWrapper}>
            {mdUp && (
              <Box
                className={classes.logoImg}
                sx={{
                  backgroundImage: `url(${LogoImg})`,
                }}
              />
            )}
            <Typography variant="h4" className={classes.formTitle} gutterBottom>
              Reset Password
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3} sx={{ mb: 3 }}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    id="email"
                    name="email"
                    rules={{ ...Validations.EMAIL_REQUIRED }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        required
                        id="email"
                        fullWidth
                        name="email"
                        className={classes.formInput}
                        label="Email address"
                        type="email"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error?.message : null}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3 }}
                    className={classes.formBtnText}
                  >
                    Reset Password
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                or
              </Typography>
            </Divider>
            <Button
              variant="outlined"
              className={classes.formBtnText}
              sx={{ mt: 3 }}
              startIcon={GoogleLogo}
            >
              Login with Google
            </Button>
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don&apos;t have an account yet?{' '}
              <Link href={RoutePaths.SIGN_UP} underline="hover">
                Sign Up
              </Link>
            </Typography>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
