/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const toastrSlice = createSlice({
  name: 'toastr',
  initialState: {
    open: false,
    message: '',
    severity: '',
  },
  reducers: {
    showToastr: (state, action) => {
      const { message, severity } = action.payload;
      state.open = true;
      state.message = message;
      state.severity = severity;
    },
    hideToastr: (state) => {
      state.open = false;
    },
  },
});

export const { showToastr, hideToastr } = toastrSlice.actions;

export default toastrSlice.reducer;
