/* eslint-disable react/prop-types */
import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import TableSkeleton from '../common/TableSkeleton';

const BookingAffiliationTable = ({ affiliatedBookings, dataLoaded }) => {
  const columns = [
    { name: 'Promocode', id: 'promocode', width: '25%' },
    { name: 'Excursion', id: 'excursion', width: '25%' },
    { name: 'Used On', id: 'usedAt', width: '25%' },
    { name: 'Commission', id: 'commission', width: '10%', align: 'right' },
  ];

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead sx={{ background: '#f0f0f0' }}>
            <TableRow>
              {columns.map((col) => (
                <TableCell
                  sx={{ fontWeight: 'bold' }}
                  width={col.width}
                  align={col.align || 'left'}
                  key={`col-${col.id}`}
                >
                  {col.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!dataLoaded && <TableSkeleton cols={4} />}
            {dataLoaded && affiliatedBookings && affiliatedBookings.length === 0 && (
              <TableCell align="center" size="medium" colSpan={4} sx={{ color: 'red' }}>
                No records found
              </TableCell>
            )}
            {dataLoaded &&
              affiliatedBookings &&
              affiliatedBookings.map((row) => (
                <TableRow key={`sb-${row.id}`}>
                  <TableCell>{row.promocode}</TableCell>
                  <TableCell>{row.excursion}</TableCell>
                  <TableCell>{row.usedOn}</TableCell>
                  <TableCell align="right">{row.commission}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default BookingAffiliationTable;
