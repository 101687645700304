import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container } from '@mui/material';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';

const ErrorWrapper = ({ children }) => (
  <Box>
    <Navbar />
    <Container
      sx={{
        display: 'flex',
        minHeight: '95vh',
        maxWidth: 'none',
        flexDirection: 'column',
      }}
      maxWidth="xl"
    >
      {children}
    </Container>
    <Footer />
  </Box>
);

ErrorWrapper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
};

export default ErrorWrapper;
