/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  const qaList = [
    {
      question: 'How much will I earn?',
      answer: 'You receive a 5-8% commission for every booked experience using your QR code.',
    },
    {
      question: 'Who can become a CanAm Affiliate?',
      answer:
        "Anyone can qualify! We're always looking for enthusiastic affiliates who are passionate about travel.",
    },
    {
      question: 'What travel tours and activities do you offer?',
      answer:
        'As a growing company, we are constantly expanding our activities. CanAm currently offers over 15 unique tours and excursions in Punta Cana. We negotiate exciting deals with various vendors daily, from thrilling snorkelling adventures with marine creatures to unforgettable catamaran party boat experiences and ATV ride tours. You can earn a commission on any activity on CanAmExcursions.com.',
    },
    {
      question: 'How easy is it to navigate the website?',
      answer: (
        <>
          CanAm&apos;s site is user-friendly, minimalistic, and straightforward. If you have any
          questions, feel free to contact us at{' '}
          <Link color="primary" href="mailto:info@canamexcursions.com">
            info@canamexcursions.com
          </Link>
        </>
      ),
    },
    {
      question: 'Where and When Will I Get Paid?',
      answer: 'CanAm pays affiliates through PayPal every 30 days.',
    },
    {
      question: 'Are there any fees or costs associated with becoming a CanAm Affiliate?',
      answer: 'There are no fees - promoting as an affiliate is completely free.',
    },
    {
      question: 'Can I promote CanAm tours and activities on social media platforms?',
      answer:
        'Yes, we recommend promoting tours via social media or printing posters if you own a brick-and-mortar business.',
    },
    {
      question:
        'Is there a minimum number of bookings or sales I need to make to receive commissions?',
      answer: 'Nope - you can claim your commission earnings with no minimum earnings.',
    },
    {
      question: 'What happens if a customer cancels their booking? Will my commission be affected?',
      answer:
        'Customers using your QR code must keep bookings for affiliates to earn a commission.',
    },
  ];

  return (
    <Box my={3}>
      <Box width="max-content" mx="auto">
        <Typography
          variant="h4"
          component="h4"
          color="#002C44"
          textAlign="center"
          sx={{
            width: 'fit-content',
            fontFamily: 'Raleway !important',
            fontWeight: 400,
            borderBottom: '1px solid #023C684D',
            paddingBottom: 1,
            my: 3,
          }}
        >
          FAQs
        </Typography>
      </Box>
      <>
        {qaList.map((qa) => (
          <Accordion
            key={qa.question}
            sx={{
              boxShadow: 'none !important',
              '& .MuiAccordionSummary-root.Mui-expanded .MuiTypography-root': {
                fontWeight: 'bold',
              },
            }}
            disableGutters
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`Q-${qa.question}`}
              id={`Q-${qa.question}`}
              sx={{
                my: 0,
              }}
            >
              <Typography> {qa.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography pl={2}> {qa.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </>
    </Box>
  );
};

export default FAQ;
