import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Container, TextField, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import PrivateWrapper from '../layouts/Private';
import BookingAffiliationTable from '../components/affiliates/BookingAffiliationTable';
import { showErrorToastr } from '../store/actions/toastrActions';
import { getSalesFromBackend } from '../services/Auth';
import GetCookie from '../utils/common';

const Sales = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reloadData, setReloadData] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [rows, setRows] = useState([]);

  const onApply = () => {
    setReloadData(!reloadData);
    // TODO: useEffect called  service should take into consideration startDate and endDate and show data as data is reloaded
  };

  const onClear = () => {
    setStartDate(null);
    setEndDate(null);
    setReloadData(!reloadData);
    // TODO: useEffect called  service should take into consideration startDate and endDate and show data as data is reloaded
  };

  const getSales = async (formattedstartDate, formattedendDate) => {
    const row = await getSalesFromBackend(GetCookie('refId'), formattedstartDate, formattedendDate);
    setRows(row);
  };

  useEffect(() => {
    setDataLoaded(false);
    const formattedStartDate = startDate ? format(new Date(startDate), 'yyyy-MM-dd') : null;
    const formattedEndDate = startDate ? format(new Date(endDate), 'yyyy-MM-dd') : null;
    // TODO: useEffect called  service should take into consideration startDate and endDate and show data as data is reloaded
    setRows([]);
    getSales(formattedStartDate, formattedEndDate);
    setDataLoaded(true);
  }, [reloadData]);

  return (
    <PrivateWrapper>
      <Paper elevation={0}>
        <Box minHeight="80vh" display="flex" justifyContent="center">
          <Container>
            <Box display="flex" sx={{ flexDirection: { xs: 'column', lg: 'row' }, py: 2 }}>
              <Typography variant="h5" my={2} fontWeight="bold">
                Sales
              </Typography>
              <Box
                sx={{
                  flexDirection: { xs: 'column', lg: 'row' },
                  ml: { lg: 'auto' },
                  alignItems: 'center',
                }}
                display="flex"
                gap={2}
              >
                <DatePicker
                  onChange={(newValue) => {
                    if (endDate && new Date(newValue) > new Date(endDate)) {
                      showErrorToastr('Start date cannot be greater than end date.');
                      return;
                    }
                    setStartDate(newValue);
                  }}
                  inputFormat="YYYY/MM/DD"
                  value={startDate}
                  label="Start Date"
                  renderInput={(params) => (
                    <TextField
                      required
                      margin="dense"
                      sx={{ width: { lg: 'auto', xs: '100%' } }}
                      variant="standard"
                      {...params}
                    />
                  )}
                />
                <DatePicker
                  label="End Date"
                  inputFormat="YYYY/MM/DD"
                  onChange={(newValue) => {
                    if (startDate && new Date(newValue) < new Date(startDate)) {
                      showErrorToastr('End date cannot be less than start date.');
                      return;
                    }
                    setEndDate(newValue);
                  }}
                  value={endDate}
                  renderInput={(params) => (
                    <TextField
                      margin="dense"
                      required
                      sx={{ width: { lg: 'auto', xs: '100%' } }}
                      variant="standard"
                      {...params}
                    />
                  )}
                />
                <Button
                  disableElevation
                  sx={{
                    width: { lg: 'auto', xs: '100%' },
                    ml: { lg: '10px' },
                    px: 5,
                    py: 1,
                  }}
                  onClick={onApply}
                  variant="contained"
                >
                  Apply
                </Button>
                <Button
                  disableElevation
                  sx={{ width: { lg: 'auto', xs: '100%' }, px: 5, py: 1 }}
                  variant="contained"
                  onClick={onClear}
                >
                  Clear
                </Button>
              </Box>
            </Box>
            <BookingAffiliationTable affiliatedBookings={rows} dataLoaded={dataLoaded} />
          </Container>
        </Box>
      </Paper>
    </PrivateWrapper>
  );
};

export default Sales;
