/* eslint-disable react/prop-types */
import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import TableSkeleton from '../common/TableSkeleton';

const PaymentsTable = ({ affiliatePayments, dataLoaded }) => {
  const columns = [
    { name: 'Payment Id', id: 'paymentId', width: '25%' },
    { name: 'Transaction Id', id: 'transactionId', width: '25%' },
    { name: 'Date', id: 'date', width: '25%' },
    { name: 'Reason', id: 'reason', width: '25%' },
    { name: 'Amount', id: 'amount', width: '10%', align: 'right' },
  ];

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead sx={{ background: '#f0f0f0' }}>
            <TableRow>
              {columns.map((col) => (
                <TableCell
                  sx={{ fontWeight: 'bold' }}
                  width={col.width}
                  align={col.align || 'left'}
                  key={`col-${col.id}`}
                >
                  {col.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!dataLoaded && <TableSkeleton cols={5} />}
            {dataLoaded && affiliatePayments && affiliatePayments.length === 0 && (
              <TableCell align="center" size="medium" colSpan={5} sx={{ color: 'red' }}>
                No records found
              </TableCell>
            )}
            {dataLoaded &&
              affiliatePayments &&
              affiliatePayments.map((row) => (
                <TableRow key={`sb-${row.id}`}>
                  <TableCell>{row.paymentId}</TableCell>
                  <TableCell>{row.transactionId}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.reason}</TableCell>
                  <TableCell align="right">{row.amount}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PaymentsTable;
