import React from 'react';
import PublicWrapper from '../layouts/Public';
import HowItWorks from '../components/landing/HowItWorks';
import AffiliateBenefits from '../components/landing/AffiliateBenefits';
import SecurePayment from '../components/landing/SecurePayment';
import AboutUs from '../components/landing/AboutUs';
import { AffiliatesBanner, AffiliateEarnMoneyBanner } from '../components/landing/Banners';
import Activities from '../components/landing/Activities';
import FAQ from '../components/landing/FAQ';

const HomePublic = () => (
  <PublicWrapper>
    <AffiliatesBanner />
    <HowItWorks />
    <AffiliateBenefits />
    <SecurePayment />
    <AffiliateEarnMoneyBanner />
    <Activities />
    <AboutUs />
    <FAQ />
  </PublicWrapper>
);

export default HomePublic;
