import React from 'react';
import { Icon } from '@mui/material';
import GoogleSVG from '../../assets/icons/google_logo.svg';

const GoogleLogo = (
  <Icon
    sx={{
      height: '30px !important',
    }}
  >
    <img alt="google-icon" src={GoogleSVG} />
  </Icon>
);

export default GoogleLogo;
