import React, { useEffect, useState } from 'react';
import {
  Container,
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  Checkbox,
  IconButton,
  FormControlLabel,
  MenuItem,
  InputAdornment,
  Link,
  Backdrop,
  CircularProgress,
  Autocomplete,
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useForm, Controller } from 'react-hook-form';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import Cookies from 'js-cookie';
import { onAuthStateChanged } from 'firebase/auth';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import Validations from '../../utils/Validations';
import AuthStyles from '../../styles/views/Auth';
import PublicWrapper from '../../layouts/Public';
import AffiliateLeftBanner from '../../assets/images/affiliate_signup.png';
import { showErrorToastr, showSuccessToastr } from '../../store/actions/toastrActions';
import RoutePaths from '../../configs/Routes';
import { auth, registerWithEmailAndPassword } from '../../services/Auth';
import 'react-phone-number-input/style.css';
import { COUNTRIES } from '../../configs/Constants';

const useStyles = makeStyles(AuthStyles);

const OnboardAffiliate = () => {
  const classes = useStyles();
  const { control, handleSubmit, watch, setValue } = useForm({ mode: 'onChange' });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isLoggedIn, setLoggedIn] = useState(false);

  const password = watch('password', '');

  useEffect(() => {
    if (isLoggedIn) {
      navigate(RoutePaths.HOME);
    }
  });

  useEffect(() => {
    // TODO: add original auth state later

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, handle authenticated state
        // You can access the user object for further processing

        setLoggedIn(true);
      } else {
        // User is signed out, handle unauthenticated state

        setLoggedIn(false);
      }
    });
    unsubscribe();
  }, []);

  const emailSignup = async (data) => {
    let result = { item: [] };
    result = await registerWithEmailAndPassword(
      `${data.firstName} ${data.lastName}`,
      `${data.email}`,
      `${data.password}`,
      data
    );
    return result;
  };

  const SetCookie = (key, value) => {
    Cookies.set(key, value, {
      expires: 7,
    });
  };

  const [isTOSAccepted, setIsTOSAccepted] = useState(false);
  const [isPAAccepted, setIsPAAccepted] = useState(false);

  const onSubmit = async (data) => {
    try {
      const toSubmitData = { ...data, country: data.country ? data?.country?.label || '' : '' };

      if (!isTOSAccepted) {
        showErrorToastr('Please read and agree to the Terms & Conditions');
        return;
      }
      if (!isPAAccepted) {
        showErrorToastr('Please read and agree to the Privacy Agreement');
        return;
      }
      setIsLoading(true);

      const user = await emailSignup(toSubmitData);

      if (!(await user.success)) {
        showErrorToastr(user?.message || 'User Already Exists! Please Login');
        setIsLoading(false);
        navigate(RoutePaths.SIGN_UP);
      } else {
        showSuccessToastr(await user?.message);
        SetCookie('token', await user.data.uid);
        SetCookie('email', await user.data.email);
        SetCookie('name', await user.data.name);
        SetCookie('refId', await user.data.refId);
        setIsLoading(false);
        navigate(RoutePaths.HOME);
      }
    } catch (err) {
      setIsLoading(false);
      showErrorToastr('Something Went Wrong!');
    }
  };

  return (
    <>
      {!isLoggedIn && (
        <PublicWrapper>
          <Box minHeight="100vh">
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
            >
              <CircularProgress sx={{ color: '#fff' }} />
            </Backdrop>

            <Grid container rowSpacing={1} component="main">
              <Grid
                sx={{
                  background: `url(${AffiliateLeftBanner})`,
                  minHeight: { xs: '50vh', md: '100vh', lg: 'auto' },
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  mt: 1,

                  backgroundRepeat: 'no-repeat',
                }}
                item
                xs={12}
                sm={12}
                md={6}
              >
                <Box>
                  <Typography align="center" sx={{ fontSize: { xs: '20px', sm: '32px' } }}>
                    You are almost there!
                  </Typography>
                  <Typography
                    align="center"
                    sx={{ fontSize: { xs: '32px', sm: '54px' }, fontWeight: 'bold' }}
                    color="primary"
                    variant="h2"
                  >
                    Start Earning <br /> Money Today!
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box
                  className={classes.formWrapper}
                  sx={{ background: '#fff', minHeight: { xs: '40vh', md: '100vh', lg: 'auto' } }}
                >
                  <Container maxWidth="md">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Grid container spacing={3} sx={{ mb: 3 }}>
                        <Grid item xs={12}>
                          <Typography variant="h5" my={2} fontWeight="bold">
                            Become an affiliate
                          </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Controller
                            control={control}
                            id="firstName"
                            name="firstName"
                            rules={{ ...Validations.REQUIRED }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                required
                                id="firstName"
                                name="firstName"
                                label="First Name"
                                fullWidth
                                type="text"
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error?.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Controller
                            control={control}
                            id="lastName"
                            name="lastName"
                            rules={{ ...Validations.REQUIRED }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                required
                                id="lastName"
                                name="lastName"
                                label="Last Name"
                                fullWidth
                                type="text"
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error?.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            control={control}
                            id="email"
                            name="email"
                            rules={{ ...Validations.EMAIL_REQUIRED }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                required
                                id="email"
                                fullWidth
                                name="email"
                                label="Email address"
                                type="email"
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error?.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            control={control}
                            id="password"
                            name="password"
                            rules={{ ...Validations.PASSWORD_REQUIRED }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                name="password"
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                fullWidth
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error?.message : null}
                                rules={{
                                  required: {
                                    value: true,
                                    message: 'This field is required.',
                                  },
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                      >
                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            control={control}
                            id="confirmPassword"
                            name="confirmPassword"
                            rules={{
                              ...Validations.PASSWORD_REQUIRED,
                              validate: (cP) => cP === password || 'Passwords do not match.',
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                name="confirmPassword"
                                label="Confirm Password"
                                type={showPassword ? 'text' : 'password'}
                                fullWidth
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error?.message : null}
                                rules={{
                                  required: {
                                    value: true,
                                    message: 'This field is required.',
                                  },
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() => setShowConfirmPassword(!showPassword)}
                                        edge="end"
                                      >
                                        {showConfirmPassword ? (
                                          <VisibilityIcon />
                                        ) : (
                                          <VisibilityOffIcon />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            id="phone"
                            name="phone"
                            control={control}
                            rules={{ validate: matchIsValidTel }}
                            render={({ field, fieldState: { error, invalid } }) => (
                              <MuiTelInput
                                {...field}
                                required
                                fullWidth
                                label="Phone Number"
                                error={!!error || invalid}
                                helperText={
                                  error || invalid ? error?.message || 'Invalid Phone Number' : null
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            control={control}
                            id="company"
                            name="company"
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                id="company"
                                fullWidth
                                name="company"
                                label="Company"
                                type="text"
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error?.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            control={control}
                            name="country"
                            id="country"
                            rules={{ ...Validations.REQUIRED }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <Autocomplete
                                name="country"
                                id="country"
                                options={COUNTRIES}
                                autoHighlight
                                onChange={(e, newValue) => {
                                  onChange(e);
                                  setValue('country', newValue);
                                }}
                                onInputChange={(event, newInputValue) => {
                                  setValue('country', newInputValue);
                                }}
                                value={value}
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                  >
                                    <img
                                      loading="lazy"
                                      width="20"
                                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                      alt=""
                                    />
                                    {option.label} ({option.code})
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    error={!!error}
                                    label="Choose a country"
                                    helperText={error ? error?.message : null}
                                  />
                                )}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            control={control}
                            id="type"
                            name="type"
                            rules={Validations.REQUIRED}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                margin="dense"
                                label="Type"
                                select
                                fullWidth
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error?.message : null}
                              >
                                <MenuItem value="Influencer">Influencer</MenuItem>
                                <MenuItem value="Agent">Agent</MenuItem>
                                <MenuItem value="Owner">Owner</MenuItem>
                              </TextField>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            control={control}
                            id="tellUsAboutYourself"
                            name="tellUsAboutYourself"
                            rules={{ ...Validations.REQUIRED }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                required
                                id="tellUsAboutYourself"
                                name="tellUsAboutYourself"
                                label="Tell us about yourself"
                                fullWidth
                                multiline
                                minRows={11}
                                type="text"
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error?.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            control={control}
                            id="facebook"
                            name="facebook"
                            rules={{ ...Validations.VALIDATE_URL }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                id="facebook"
                                fullWidth
                                name="facebook"
                                label="Facebook"
                                type="text"
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error?.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            control={control}
                            id="instagram"
                            name="instagram"
                            rules={{ ...Validations.VALIDATE_URL }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                id="instagram"
                                fullWidth
                                name="instagram"
                                label="Instagram"
                                type="text"
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error?.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            control={control}
                            id="tiktok"
                            name="tiktok"
                            rules={{ ...Validations.VALIDATE_URL }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                id="tiktok"
                                fullWidth
                                name="tiktok"
                                label="Tiktok"
                                type="text"
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error?.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            control={control}
                            id="website"
                            name="website"
                            rules={{ ...Validations.VALIDATE_URL }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                id="website"
                                fullWidth
                                name="website"
                                label="Website"
                                type="text"
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error?.message : null}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                edge="end"
                                checked={isTOSAccepted}
                                sx={{ mr: 1 }}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  setIsTOSAccepted(e.target.checked);
                                }}
                              />
                            }
                            label={
                              <>
                                <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
                                  I have read and agree to the{' '}
                                  <Link to={RoutePaths.TERMS} component={RouterLink}>
                                    Terms & Conditions
                                  </Link>
                                </Typography>
                              </>
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                edge="end"
                                checked={isPAAccepted}
                                sx={{ mr: 1 }}
                                onChange={(e) => {
                                  setIsPAAccepted(e.target.checked);
                                }}
                              />
                            }
                            label={
                              <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
                                I have read and agree to the{' '}
                                <Link to={RoutePaths.PRIVACY} component={RouterLink}>
                                  Privacy Agreement
                                </Link>
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button variant="contained" fullWidth type="submit" disableElevation>
                            Become an affiliate
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </PublicWrapper>
      )}
    </>
  );
};

export default OnboardAffiliate;
