import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemIcon,
  Icon,
} from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import InstaLogo from '../../assets/icons/instagram.svg';
import FBLogo from '../../assets/icons/fb.svg';
import LandingPageStyles from '../../styles/views/Landing';
import RoutePaths from '../../configs/Routes';
import { FACEBOOK_LINK, INSTAGRAM_LINK } from '../../configs/Constants';

const useStyles = makeStyles(LandingPageStyles);

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.footerContainer}>
      <Container className={classes.gridsSectionContainer}>
        <Grid container>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <List
              subheader={
                <ListSubheader className={classes.footerLinkHeader} component="div">
                  CanAmExcursions
                </ListSubheader>
              }
            >
              <ListItem component={Link} to={RoutePaths.ABOUT_US} disablePadding>
                <ListItemText className={classes.footerLinks} primary="About us" />
              </ListItem>
              <ListItem component={Link} to={RoutePaths.BLOG} disablePadding>
                <ListItemText className={classes.footerLinks} primary="Blog" />
              </ListItem>
              <ListItem component={Link} to={RoutePaths.CONTACT_US} disablePadding>
                <ListItemText className={classes.footerLinks} primary="Contact us" />
              </ListItem>
              <ListItem component={Link} to={RoutePaths.FAQ} disablePadding>
                <ListItemText className={classes.footerLinks} primary="FAQ" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <List
              subheader={
                <ListSubheader className={classes.footerLinkHeader} component="div">
                  Legals
                </ListSubheader>
              }
            >
              <ListItem component={Link} to={RoutePaths.TERMS} disablePadding>
                <ListItemText className={classes.footerLinks} primary="Terms and conditions" />
              </ListItem>
              <ListItem component={Link} to={RoutePaths.PRIVACY} disablePadding>
                <ListItemText className={classes.footerLinks} primary="Privacy Policy" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <List
              subheader={
                <ListSubheader className={classes.footerLinkHeader}>Contact Info</ListSubheader>
              }
            >
              <ListItem disablePadding>
                <ListItemText className={classes.footerLinks} primary="310 Hanna Street East." />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText className={classes.footerLinks} primary="Windsor, ON Canada" />
              </ListItem>
              <ListItem component="a" href="mailto:info@canamexcursions.com" disablePadding>
                <ListItemText className={classes.footerLinks} primary="info@canamexcursions.com" />
              </ListItem>
              <ListItem component="a" href="tel:+18499361311" disablePadding>
                <ListItemText className={classes.footerLinks} primary="DR: +1 (849) 936-1311" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <ListSubheader className={classes.footerLinkHeader} component="div">
              Follow Us
            </ListSubheader>
            <List component={Box} display="flex" alignItems="center" gap={1} direction="row">
              <ListItem component="a" href={INSTAGRAM_LINK} disablePadding sx={{ width: 'auto' }}>
                <ListItemIcon>
                  <Icon className={classes.icon}>
                    <img alt="instagrtam-logo" src={InstaLogo} className={classes.socialIcon} />
                  </Icon>
                </ListItemIcon>
              </ListItem>
              <ListItem sx={{ width: 'auto' }} component="a" href={FACEBOOK_LINK} disablePadding>
                <ListItemIcon>
                  <Icon className={classes.icon}>
                    <img alt="facebook-logo" src={FBLogo} className={classes.socialIcon} />
                  </Icon>
                </ListItemIcon>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
