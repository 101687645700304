import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  getIdToken,
  onAuthStateChanged,
} from 'firebase/auth';
import { getFirestore, setDoc, doc, getDoc } from 'firebase/firestore';
import { BackendServer } from '../configs/Constants';
// import { GetCookie } from '../../utils/utils';
// import { encryptData } from './encryption';

const firebaseConfig = {
  apiKey: 'AIzaSyDyES9JibLY_oRWSeSuDenNUbM4_boNQgo',
  authDomain: 'bookaexcursion-36fb8.firebaseapp.com',
  databaseURL: 'https://bookaexcursion-36fb8-default-rtdb.firebaseio.com',
  projectId: 'bookaexcursion-36fb8',
  storageBucket: 'bookaexcursion-36fb8.appspot.com',
  messagingSenderId: '430070302464',
  appId: '1:430070302464:web:eb00fec931eaff8f4515e5',
  measurementId: 'G-T0QR7V1F18',
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const roleMap = {
  0: 'customer',
  1: 'affiliate',
  2: 'admin',
  3: 'customerAffiliate',
  customer: 0,
  affiliate: 1,
  admin: 2,
  customerAffiliate: 3,
};

const storeContactUsDetails = async (toSubmitData) => {
  await setDoc(doc(db, 'contactus_affiliate', `${toSubmitData.email}`), toSubmitData);
};

export const setCustomClaim = async (uidToken, claim) => {
  await new Promise((resolve) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        unsubscribe(); // Unsubscribe to prevent multiple calls
        resolve(user);
      } else {
        // Keep waiting for authentication state to be restored
      }
    });
  });

  const user = auth.currentUser;
  const token = await getIdToken(user, true);
  const url = `${BackendServer.baseURL}/api/setCustomClaims`;
  const data = {
    uid: uidToken,
    ...claim,
  };
  const responseSetClaim = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  });
  return responseSetClaim;
};

export const getCustomClaim = async (uidToken) => {
  await new Promise((resolve) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        unsubscribe(); // Unsubscribe to prevent multiple calls
        resolve(user);
      } else {
        // Keep waiting for authentication state to be restored
      }
    });
  });
  const user = auth.currentUser;
  const token = await getIdToken(user, true);
  const url = `${BackendServer.baseURL}/api/getCustomClaims`;
  const data = {
    uid: uidToken,
  };
  const responseGetClaim = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  });
  return responseGetClaim;
};

export const RegisterAffiliateOnBoarding = async (data) => {
  try {
    const url = `${BackendServer.baseURL}/registeraffiliate`;
    const responseOfAPI = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return { status: await responseOfAPI.json() };
  } catch ({ response }) {
    return { status: 403 };
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  let user;
  try {
    const jsonData = await signInWithEmailAndPassword(auth, email, password);

    user = jsonData.user;
    const docRef = doc(db, 'Users', user.uid);
    const docSnapshot = await getDoc(docRef);
    let refId = null;
    let name = null;

    if (await docSnapshot.exists()) {
      // Extract field keys as array
      const data = await docSnapshot.data();
      refId = await data.ref_id;
      name = await data.name;
    }
    const response = await getCustomClaim(await user.uid);
    const resData = await response.json();
    switch (await resData.status) {
      case 200: {
        const claim = await resData.data;
        if ((await claim.isAffiliate) === true) {
          return {
            success: true,
            message: 'Affiliate Login Successfull',
            data: {
              uid: await user.uid,
              name: await name,
              email: await user.email,
              refId: await refId,
            },
          };
        }
        return {
          success: false,
          message: 'User Do not have Affiliate Priviledge!',
        };
      }
      case 401:
        return {
          success: false,
          message: 'Affiliate Login Failed! [Backend: Insufficient Access]',
        };
      case 500:
        return {
          success: false,
          message: 'Affiliate Login Failed! [Backend: Internal Error]',
        };
      default:
        return {
          success: false,
          message: 'Affiliate Failed!',
        };
    }
  } catch (err) {
    switch (err.code) {
      case 'auth/wrong-password':
        return {
          success: false,
          message: 'You Entered Wrong Password',
        };
      case 'auth/too-many-requests':
        return {
          success: false,
          message: 'Please try again later',
        };
      case 'auth/user-not-found':
        return {
          success: false,
          message: 'Affiliate Not Found',
        };
      case 'auth/network-request-failed':
        return {
          success: false,
          message: 'Please check the combination of email and password',
        };
      default:
        return {
          success: false,
          message: 'Affiliate Login failed',
        };
    }
  }
};

export const getSalesFromBackend = async (uidToken, startDateP, endDateP) => {
  await new Promise((resolve) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        unsubscribe(); // Unsubscribe to prevent multiple calls
        resolve(user);
      } else {
        // Keep waiting for authentication state to be restored
      }
    });
  });

  const user = await auth.currentUser;
  const token = await getIdToken(await user, true);

  const url = `${BackendServer.baseURL}/getSales`;
  const data = {
    id: uidToken,
    startDate: startDateP,
    endDate: endDateP,
  };

  const responseGetSales = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await token,
    },
    body: JSON.stringify(data),
  });
  const jsonResponseData = await responseGetSales.json();
  return jsonResponseData.data.bookingAffliliations;
};

export const getBalanceCommissionTotalSales = async (uidToken) => {
  await new Promise((resolve) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        unsubscribe(); // Unsubscribe to prevent multiple calls
        resolve(user);
      } else {
        // Keep waiting for authentication state to be restored
      }
    });
  });

  const user = await auth.currentUser;
  const token = await getIdToken(await user, true);

  const url = `${BackendServer.baseURL}/getBalanceAndSales`;
  const data = {
    id: uidToken,
  };

  const responseGetSales = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await token,
    },
    body: JSON.stringify(data),
  });
  const jsonResponseData = await responseGetSales.json();
  return jsonResponseData.data;
};

const registerWithEmailAndPassword = async (name, email, password, toSubmitData) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const username = email.split('@')[0];
    const { user } = res;
    await setDoc(doc(db, 'Users', `${user.uid}`), {
      uid: user.uid,
      name,
      authProvider: 'local',
      email,
      role: roleMap.affiliate,
      ref_id: username,
    });
    const response = await setCustomClaim(user.uid, { customClaims: { isAffiliate: true } });
    const resData = await response.json();
    switch (await resData?.status) {
      case 200: {
        const dataApi = {
          email: toSubmitData.email,
          paypalCustomerEmail: '',
          firstName: toSubmitData.firstName,
          lastName: toSubmitData.lastName,
          company: toSubmitData.company || '',
          country: toSubmitData.country || '',
          phoneNumber: toSubmitData.phone,
          description: toSubmitData.tellUsAboutYourself,
          type: toSubmitData.type || '',
          facebookLink: toSubmitData.facebook || '',
          instagramLink: toSubmitData.instagram || '',
          tiktokLink: toSubmitData.tiktok || '',
          websiteLink: toSubmitData.website || '',
        };
        const responseBackend = await RegisterAffiliateOnBoarding(dataApi);
        if ((await responseBackend.status.status) === 200) {
          const docRef = doc(db, 'Users', user.uid);
          const docSnapshot = await getDoc(docRef);
          let refId = null;

          if (await docSnapshot.exists()) {
            // Extract field keys as array
            const data = await docSnapshot.data();
            refId = await data.ref_id;
          }

          return {
            success: true,
            message: 'Affiliate Registered Successfully!',
            data: {
              uid: await user.uid,
              name: await name,
              email: await user.email,
              refId: await refId,
            },
          };
        }
        if ((await responseBackend.status.status) === 402)
          return {
            success: false,
            message: 'Affiliate with Same Email ID already Exists',
          };
        if ((await responseBackend.status.status) === 401)
          return {
            success: false,
            message: 'Same Promocode already Exists. Please use different email ID!',
          };
        return {
          success: false,
          message: 'API Call Error',
        };
      }
      case 401:
        return {
          success: false,
          message: 'Affiliate Signup Failed! [Backend: Insufficient Access]',
        };
      case 500:
        return {
          success: false,
          message: 'Affiliate Signup Failed! [Backend: Internal Error]',
        };
      default:
        return {
          success: false,
          message: 'Signup Failed!',
        };
    }
  } catch (err) {
    switch (err.code) {
      case 'auth/email-already-in-use':
        return {
          success: false,
          message: 'Affiliate Already Exists',
        };
      case 'auth/network-request-failed':
        return {
          success: false,
          message: 'Email Already Exists',
        };
      default:
        return {
          success: false,
          message: 'Affiliate Signup failed',
        };
    }
  }
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return {
      success: true,
      message: 'Reset Password Link Sent to your Email',
    };
  } catch (err) {
    return {
      success: false,
      message: 'Please Enter Valid Email',
    };
  }
};
const logout = async () => {
  await signOut(auth);
};
export {
  auth,
  db,
  app,
  storeContactUsDetails,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};
