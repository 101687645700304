import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Container } from '@mui/material';
import PrivateWrapper from '../layouts/Private';
import PaymentsTable from '../components/affiliates/PaymentsTable';

const Payments = () => {
  // eslint-disable-next-line no-unused-vars
  const [reloadData, setReloadData] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setDataLoaded(false);

    // TODO: add service
    setRows([]);
    setDataLoaded(true);
  }, [reloadData]);

  return (
    <PrivateWrapper>
      <Paper elevation={0}>
        <Box minHeight="80vh" display="flex" justifyContent="center">
          <Container>
            <Typography variant="h5" my={2} fontWeight="bold">
              Payment History
            </Typography>
            <PaymentsTable affiliatePayments={rows} dataLoaded={dataLoaded} />
          </Container>
        </Box>
      </Paper>
    </PrivateWrapper>
  );
};

export default Payments;
