import { showToastr, hideToastr } from '../reducers/toastrSlice';
import store from '../store';

export const showSuccessToastr = (message) => {
  store.dispatch(showToastr({ message, severity: 'success' }));
};

export const showErrorToastr = (message) => {
  store.dispatch(showToastr({ message, severity: 'error' }));
};

export const hideToastrAction = () => {
  store.dispatch(hideToastr());
};
