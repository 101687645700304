import {
  List,
  ListItemIcon,
  ListItemButton,
  Collapse,
  Box,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import RoutePaths from '../../configs/Routes';
import { handleCloseMainNavbarDrawer } from '../../store/actions/drawerActions';

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  transition: theme.transitions.create('color'),
  padding: '0 16px',
  fontWeight: 'normal !important',
  fontSize: '16px !important',
  lineHeight: '19px !important',
  color: ' rgba(0, 0, 0, 0.75)',
  textDecoration: 'none', // remove default underline
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
  marginBottom: '8px',
  ':root': {
    '&.active': {
      // add styles for active NavLink
      color: `${theme.palette.primary.main} !important`,
      background: 'none !important',
      backgroundColor: 'transparent !important',
    },
    '&:hover': {
      color: `${theme.palette.primary.main} !important`,
      background: 'none !important',
      backgroundColor: 'transparent !important',
    },
  },
  '&.active': {
    // add styles for active NavLink
    color: `${theme.palette.primary.main} !important`,
    background: 'none !important',
    backgroundColor: 'transparent !important',
  },
  '&:hover': {
    color: `${theme.palette.primary.main} !important`,
    background: 'none !important',
    backgroundColor: 'transparent !important',
  },
  [theme.breakpoints.down('md')]: {
    fontWeight: '700 !important',
    fontSize: '18px !important',
    lineHeight: '29px !important',
  },
}));

const UserMainMenu = ({ forMobile }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { pathname } = useLocation();

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
    handleCloseMainNavbarDrawer();
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleToogle = () => {
    setOpen((ps) => !ps);
  };

  useEffect(() => {
    if (open) {
      handleClose();
    }
  }, [pathname]);

  const dropDownItems = [{ label: 'My Profile', to: RoutePaths.MANAGE_PROFILE }];

  dropDownItems.push({ label: 'Logout', to: RoutePaths.LOGOUT });

  if (forMobile)
    return (
      <UserMobileMenu
        dropDownItems={dropDownItems}
        handleClose={handleClose}
        handleToogle={handleToogle}
        open={open}
      />
    );

  return (
    <UserDesktopMenu
      anchorEl={anchorEl}
      dropDownItems={dropDownItems}
      handleClose={handleClose}
      onOpen={handleOpen}
      open={open}
    />
  );
};

UserMainMenu.propTypes = {
  forMobile: PropTypes.bool.isRequired,
};

const UserMobileMenu = ({ dropDownItems, handleToogle, open, handleClose }) => (
  <>
    <ListItemStyle onClick={handleToogle} underline="none">
      My Account
      <ListItemIcon>{!open ? <ArrowRightIcon /> : <ArrowDropDownIcon />}</ListItemIcon>
    </ListItemStyle>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Box sx={{ marginLeft: '16px !important', display: 'flex', flexDirection: 'column-reverse' }}>
        <List disablePadding sx={{ px: 2 }}>
          {dropDownItems.map((item) => (
            <ListItemStyle
              key={item.label}
              to={item.to}
              component={NavLink}
              underline="none"
              onClick={handleClose}
            >
              {item.label}
            </ListItemStyle>
          ))}
        </List>
      </Box>
    </Collapse>
  </>
);

UserMobileMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  handleToogle: PropTypes.func.isRequired,
  dropDownItems: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  handleClose: PropTypes.func.isRequired,
};

const UserDesktopMenu = ({ anchorEl, dropDownItems, onOpen, handleClose, open }) => (
  <>
    <Box display="flex" alignItems="center" justifyContent="center">
      <IconButton onClick={onOpen} aria-describedby="u-anchor">
        <AccountCircleIcon />
      </IconButton>
    </Box>

    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {dropDownItems.map((item) => (
        <MenuItem
          sx={{
            '&.active': { color: 'text.primary', typography: 'subtitle2' },
            mb: 1,
            fontWeight: 'normal !important',
            fontSize: '14px !important',
            lineHeight: '16px !important',
          }}
          component={NavLink}
          key={item.label}
          to={item.to}
          onClick={handleClose}
        >
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  </>
);

UserDesktopMenu.propTypes = {
  anchorEl: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  dropDownItems: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  handleClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
};

export default UserMainMenu;
