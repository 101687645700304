import React, { useEffect, useState } from 'react';
import { Alert, Avatar, Box, Card, CircularProgress, Grid, Typography, alpha } from '@mui/material';
import PaymentsIcon from '@mui/icons-material/Payments';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PercentIcon from '@mui/icons-material/Percent';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../services/Auth';
import PrivateWrapper from '../layouts/Private';
import { getAgentOverview } from '../services/Affiliate';
import GetCookie from '../utils/common';
import HomePublic from './HomePublic';

const Home = () => {
  const [data, setData] = useState({ balance: 0, sales: 0, commission: 0 });
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const id = GetCookie('refId');
  useEffect(() => {
    setDataLoaded(false);
    getAgentOverview(id).then((d) => {
      setData(d);
      setDataLoaded(true);
    });
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, handle authenticated state
        // You can access the user object for further processing

        setIsLoggedIn(true);
      } else {
        // User is signed out, handle unauthenticated state

        setIsLoggedIn(false);
      }
    });
    unsubscribe();
  });

  if (isLoggedIn)
    return (
      <PrivateWrapper>
        <Box
          minHeight="80vh"
          display="flex"
          my={2}
          sx={!dataLoaded ? { alignItems: 'center', justifyContent: 'center' } : undefined}
        >
          {!dataLoaded && (
            <Box display="flex" gap={2} p={2}>
              <CircularProgress size={28} />
              <Typography>Loading...</Typography>
            </Box>
          )}
          {dataLoaded && data.approved && (
            <Grid container spacing={1}>
              <Grid item xs={12} md={3} lg={4}>
                <Box p={3} component={Card} elevation={0} display="flex" alignItems="center">
                  <Avatar
                    sx={{
                      bgcolor: (t) => alpha(t.palette.primary.main, 0.2),
                      padding: '10px',
                      height: 'auto',
                      width: 'auto',
                    }}
                  >
                    <PaymentsIcon fontSize="large" color="primary" />
                  </Avatar>
                  <Box ml="auto" display="flex" flexDirection="column" gap={2}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Balance
                    </Typography>
                    <Typography variant="h5" fontWeight="bold" textAlign="right">
                      {data.balance === 0 ? 0 : data.balance || '-'}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={3} lg={4}>
                <Box p={3} component={Card} elevation={0} display="flex" alignItems="center">
                  <Avatar
                    sx={{
                      bgcolor: (t) => alpha(t.palette.primary.main, 0.2),
                      padding: '10px',
                      height: 'auto',
                      width: 'auto',
                    }}
                  >
                    <MonetizationOnIcon fontSize="large" color="primary" />
                  </Avatar>
                  <Box ml="auto" display="flex" flexDirection="column" gap={2}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Total Sales
                    </Typography>
                    <Typography variant="h5" fontWeight="bold" textAlign="right">
                      {data.sales || '-'}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={3} lg={4}>
                <Box p={3} component={Card} elevation={0} display="flex" alignItems="center">
                  <Avatar
                    sx={{
                      bgcolor: (t) => alpha(t.palette.primary.main, 0.2),
                      padding: '10px',
                      height: 'auto',
                      width: 'auto',
                    }}
                  >
                    <PercentIcon fontSize="large" color="primary" />
                  </Avatar>
                  <Box ml="auto" display="flex" flexDirection="column" gap={2}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Commission
                    </Typography>
                    <Typography variant="h5" fontWeight="bold" textAlign="right">
                      {`${data.commission}%` || '-'}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={13} md={1} lg={14}>
                <Box p={3} component={Card} elevation={0} display="flex" alignItems="left">
                  <Box ml="0" display="flex" flexDirection="column" gap={1}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      PromoLink
                    </Typography>
                    <Typography variant="h6" fontWeight="bold" textAlign="left">
                      {`https://canamexcursions.rezgo.com?promo=${data.promocode}` || '-'}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
          {!data.approved && dataLoaded && (
            <Box
              minHeight="70vh"
              width="100%"
              p={3}
              component={Card}
              elevation={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box display="flex" flexDirection="column" gap={2}>
                <Alert severity="error"> Your Affiliate account is not approved currently.</Alert>
              </Box>
            </Box>
          )}
        </Box>
      </PrivateWrapper>
    );

  return <HomePublic />;
};

export default Home;
