import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import { makeStyles, useTheme } from '@mui/styles';
import { Box } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import LayoutWrapperStyles from '../styles/Layout';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import { handleMainNavbarDrawerToggle } from '../store/actions/drawerActions';

const useStyles = makeStyles(LayoutWrapperStyles);

const PublicWrapper = ({ children, socialsOnly, boxContent }) => {
  const classes = useStyles();
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const { mainNavbarOpen, sidebarOpen } = useSelector((state) => state.drawer);

  return (
    <Box className={classes.wrapper}>
      <Navbar
        open={mainNavbarOpen}
        toggleNav={handleMainNavbarDrawerToggle}
        socialsOnly={socialsOnly}
      />
      <Box display="flex" height="max-content" sx={{ background: '#f0f0f0' }}>
        <Box
          component={boxContent ? Box : Container}
          sx={{
            ...(sidebarOpen &&
              !lgUp && {
                marginLeft: '0px',
              }),
            py: 2,
          }}
          className={classes.content}
        >
          {children}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

PublicWrapper.defaultProps = {
  socialsOnly: false,
  boxContent: false,
};

PublicWrapper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
  socialsOnly: PropTypes.bool,
  boxContent: PropTypes.bool,
};

export default PublicWrapper;
