import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { ArrowRightAlt } from '@mui/icons-material';
import Activity1 from '../../assets/images/activities_1.jpg';
import Activity2 from '../../assets/images/activities_2.jpg';
import Activity3 from '../../assets/images/activities_3.png';
import Activity4 from '../../assets/images/activities_4.png';
import RoutePaths from '../../configs/Routes';

export function ActivitiyCard({ title, applyInventoryFilter, imgSrc, body }) {
  return (
    <Card
      sx={{
        maxWidth: 400,
        height: '100%',
        minHeight: '400px',
        background: '#fff',
        mx: 'auto',

        py: 2,
        position: 'relative',
      }}
      elevation={0}
    >
      <CardMedia
        sx={{ px: 2, borderRadius: '8px !important' }}
        component="img"
        height="194"
        image={imgSrc}
        alt={title}
      />
      <CardContent>
        <Typography variant="h6" fontWeight="bold" color="#000000BF">
          {title}
        </Typography>
        <Typography variant="body2" py={1} color="text.secondary">
          {body}
        </Typography>
      </CardContent>
      <CardActions disableSpacing sx={{ position: 'absolute', bottom: 0 }}>
        <Button
          variant="contained"
          disableElevation
          sx={{
            background: '#EBF8FF',
            color: '#002C44',
            fontWeight: '600 !important',
            '&:hover': { background: '#EBF8FF !important', color: '#002C44' },
            '&:active': { background: '#EBF8FF', color: '#002C44' },
            '&:focus': { background: '#EBF8FF', color: '#002C44' },
          }}
          onClick={() => {
            const queryParams = new URLSearchParams({
              filter: [applyInventoryFilter],
            });
            window.open(`${RoutePaths.INVENTORY}?${queryParams.toString()}`, '_blank');
          }}
        >
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}

ActivitiyCard.propTypes = {
  body: PropTypes.string.isRequired,
  applyInventoryFilter: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const Activities = () => {
  const activities = [
    {
      title: 'Saona Island',
      applyInventoryFilter: 'Saona Islands,Saona Island Tour',
      imgSrc: Activity1,
      body: 'Unwind in paradise with a Saona Island Tour in Punta Cana - pristine beaches, crystal clear waters, and breathtaking scenery.',
    },
    {
      title: 'Snorkeling',
      applyInventoryFilter: 'Snorkeling',
      imgSrc: Activity2,
      body: 'Explore the vibrant underwater world of Punta Cana while snorkeling, encountering colorful marine life and coral reefs in crystal clear waters.',
    },
    {
      title: 'Ziplining',
      applyInventoryFilter: 'Zipline',
      imgSrc: Activity3,
      body: 'Get your adrenaline pumping with our Zip lining activity in Punta Cana - an adventure through the treetops.',
    },
    {
      title: 'Buggy Rides',
      applyInventoryFilter: 'Buggy Ride',
      imgSrc: Activity4,
      body: 'Experience the thrill of off-road adventure in Punta Cana by driving a buggy through rugged terrain and scenic countryside.',
    },
  ];

  return (
    <Box display="flex" alignItems="center" flexDirection="column" my={2}>
      <Box width="fit-content" mx="auto">
        <Typography
          variant="h4"
          component="h4"
          color="#002C44"
          textAlign="center"
          sx={{
            width: 'fit-content',
            borderBottom: '1px solid #023C684D',
            paddingBottom: 1,
            mb: 2,
          }}
          fontFamily="Raleway"
          fontWeight="400"
        >
          Some of our popular Activities
          {/* <Typography
          component="span"
          sx={{
            position: 'absolute',
            bottom: 0,
            left: '50%',
            width: { xs: '50%', md: '40%' },
            transform: 'translateX(-50%)',
            height: '1px',
            backgroundColor: '#023C68',
            opacity: '30%',
          }}
        /> */}
        </Typography>
      </Box>
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        {activities.map((a) => (
          <Grid sx={{ height: '100%' }} item xs={12} md={6} lg={3}>
            <ActivitiyCard {...a} />
          </Grid>
        ))}
      </Grid>
      <Button
        sx={{ my: 2, mx: 'auto' }}
        color="primary"
        variant="outlined"
        disableElevation
        endIcon={<ArrowRightAlt />}
        onClick={() => {
          window.open(`${RoutePaths.INVENTORY}`, '_blank');
        }}
      >
        View all excursions
      </Button>
    </Box>
  );
};

export default Activities;
