/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const drawerSlice = createSlice({
  name: 'drawer',
  initialState: {
    mainNavbarOpen: false,
    sidebarOpen: true,
  },
  reducers: {
    toggleMainNavbarDrawer: (state) => {
      state.mainNavbarOpen = !state.mainNavbarOpen;
    },

    closeMainDrawer: (state) => {
      state.mainNavbarOpen = false;
    },

    openMainDrawer: (state) => {
      state.mainNavbarOpen = true;
    },

    toggleSidebarDrawer: (state) => {
      state.sidebarOpen = !state.sidebarOpen;
    },

    closeSidebarDrawer: (state) => {
      state.sidebarOpen = false;
    },

    openSidebarDrawer: (state) => {
      state.sidebarOpen = true;
    },
  },
});

export const {
  toggleMainNavbarDrawer,
  closeMainDrawer,
  openMainDrawer,
  openSidebarDrawer,
  closeSidebarDrawer,
  toggleSidebarDrawer,
} = drawerSlice.actions;

export default drawerSlice.reducer;
