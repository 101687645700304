// Style for auth pages forms
const Style = (theme) => ({
  formTitle: {
    fontSize: '40px',
    fontWeight: 700,
    lineHeight: '48px',
    letterSpacing: '0em',
    textAlign: 'left',
    paddingBottom: 24,
    [theme.breakpoints.down('md')]: {
      fontSize: '21px !important',
      fontWeight: 700,
      lineHeight: 34,
      letterSpacing: 0,
    },
  },
  emailConfirmTitle: {
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '36px',
    letterSpacing: '0em',
    textalign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: '21px !important',
      fontWeight: 700,
      lineHeight: 26,
      letterSpacing: 0,
    },
  },
  formWrapper: {
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 0),
      minHeight: 'auto',
      maxWidth: 'auto',
    },
  },
  logoImg: {
    height: '90px',
    width: '160px',
    marginTop: 30,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px  !important',
      fontWeight: 400,
      lineHeight: 17,
      letterSpacing: 0,
    },
  },
  formHelperText: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  formInput: {
    marginBottom: '46px',
  },
  '.MuiFormLabel-root': {
    fontSize: '20px !important',
  },
  '.MuiFormHelperText-root': {
    margin: '0 !important',
  },
  tosText: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  formBtnText: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
    marginTop: '46px',
    textTransform: 'capitalize !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px  !important',
      fontWeight: 400,
      lineHeight: 17,
      letterSpacing: 0,
    },
  },
  pb48: {
    paddingBottom: '48px',
  },
  formLinkText: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '28px',
    letterSpacing: '0em',
    textAlign: 'center',
  },
  formImg: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
});

export default Style;
