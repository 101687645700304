import React from 'react';

const SafetySvgrepoCom1Icon = () => (
  <svg
    preserveAspectRatio="none"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.1187 2.67558C16.257 2.72445 17.8125 5.63789 6.56434 5.68765V23.0071C6.56434 30.4929 18.3773 37.3145 20.1187 37.3145C21.86 37.3145 33.673 30.4929 33.673 23.0071V5.68765C22.6131 5.70843 23.8394 2.62851 20.1187 2.67558ZM32.167 23.0562C32.167 29.0695 20.9619 35.8007 20.1186 35.8084C19.2791 35.8162 8.11739 29.0225 8.07032 23.0562V7.61929C18.0688 7.57496 16.6861 4.97816 20.1186 4.93465C23.426 4.89269 22.3358 7.63785 32.167 7.61929V23.0562ZM14.9499 18.1125C14.6559 17.8184 14.1791 17.8184 13.885 18.1125C13.5909 18.4065 13.5909 18.8833 13.885 19.1774L18.6772 23.9696C18.9713 24.2637 19.4481 24.2637 19.7422 23.9696L27.8583 15.8534C28.1524 15.5594 28.1524 15.0826 27.8583 14.7885C27.5643 14.4945 27.0875 14.4945 26.7934 14.7885L19.2097 22.3722L14.9499 18.1125Z"
      fill="#002C44"
    />
  </svg>
);

export default SafetySvgrepoCom1Icon;
