import { configureStore } from '@reduxjs/toolkit';
import toastrReducer from './reducers/toastrSlice';
import drawerReducer from './reducers/drawerSlice';

// optional step that enables the Redux DevTools extension in your browser
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = configureStore({
  reducer: {
    toastr: toastrReducer,
    drawer: drawerReducer,
  },
  // enhancers: [composeEnhancers()],
});

export default store;
