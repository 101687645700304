import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import { makeStyles, useTheme } from '@mui/styles';
import { Box } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../services/Auth';
import LayoutWrapperStyles from '../styles/Layout';
import Footer from '../components/common/Footer';
import {
  handleCloseSidebarDrawer,
  handleMainNavbarDrawerToggle,
  handleOpenSidebarDrawer,
} from '../store/actions/drawerActions';
import { DRAWER_WIDTH } from '../configs/Constants';
import Navbar from '../components/common/Navbar';
import Sidebar from '../components/common/Sidebar';

// import RoutePaths from '../configs/Routes';
// import { showErrorToastr } from '../store/actions/toastrActions';
// import getFormatterErrorText from '../utils/FormatError';
import RoutePaths from '../configs/Routes';

const useStyles = makeStyles(LayoutWrapperStyles);

const PrivateWrapper = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const navigate = useNavigate();
  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  const { mainNavbarOpen, sidebarOpen } = useSelector((state) => state.drawer);

  useEffect(() => {
    if (!lgUp) {
      handleCloseSidebarDrawer();
    } else {
      handleOpenSidebarDrawer();
    }
  }, [lgUp]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, handle authenticated state
        // You can access the user object for further processing
      } else {
        // User is signed out, handle unauthenticated state

        navigate(RoutePaths.LOGOUT);
      }
    });
    unsubscribe();
  }, []);

  return (
    <Box className={classes.wrapper}>
      <Navbar open={mainNavbarOpen} toggleNav={handleMainNavbarDrawerToggle} />
      {sidebarOpen && <Sidebar />}
      <Box display="flex" height="max-content" sx={{ background: '#f0f0f0' }}>
        <Box
          component={Container}
          sx={{
            ...(sidebarOpen &&
              lgUp && {
                marginLeft: `${DRAWER_WIDTH + 50}px !important`,
              }),
            ...(sidebarOpen &&
              !lgUp && {
                marginLeft: '0px',
              }),
            py: 2,
          }}
          className={classes.content}
        >
          {children}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

PrivateWrapper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
};

export default PrivateWrapper;
