import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import AffiliateBanner from '../../assets/images/affiliate_home.png';
import AffiliateEarnMoneyBannerImg from '../../assets/images/earn_money_banner.png';
import RoutePaths from '../../configs/Routes';

function AffiliatesBanner() {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid
      container
      sx={{
        height: '100vh',
        maxHeight: '700px !important',
        backgroundImage: `url(${AffiliateBanner})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'cover',
        alignItems: 'center',
        justifyContent: { xs: 'center', md: 'left' },
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{ mt: -5, px: { xs: 2, md: 8 }, justifyContent: { xs: 'center', md: 'start' } }}
        container
      >
        <Typography
          zIndex={333}
          variant="h4"
          component="h2"
          gutterBottom
          fontWeight="700"
          fontFamily="Raleway"
          align={isMdScreen ? 'start' : 'center'}
        >
          Join our <br /> Affiliate Program, <br />
          <Typography
            color="primary"
            fontWeight="700"
            variant="h4"
            component="h2"
            gutterBottom
            display="inline"
            fontFamily="Raleway"
          >
            Earn Money Today!
          </Typography>
        </Typography>
        <Typography
          align={isMdScreen ? 'start' : 'center'}
          zIndex={333}
          variant="subtitle1"
          fontWeight={300}
          gutterBottom
          mb={2}
        >
          As an affiliate, you&apos;ll be able to promote our exciting travel packages to your
          friends, family & followers and earn up to $10 for every successful referral.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          LinkComponent={Link}
          to={RoutePaths.SIGN_UP}
          disableElevation
          sx={{ px: 3 }}
        >
          Get Started Now
        </Button>
      </Grid>
      <Grid item />
    </Grid>
  );
}

const AffiliateEarnMoneyBanner = () => (
  <Box my={4}>
    <Grid container>
      <Grid
        sx={{
          background: `url(${AffiliateEarnMoneyBannerImg})`,
          backgroundRepeat: 'no-repeat',
          display: { xs: 'none', md: 'block' },
          height: { md: '80vh', xs: 'max-content' },
          maxHeight: '772px',
        }}
        item
        xs={12}
        sm={12}
        md={6}
      />

      <Grid
        item
        xs={12}
        md={6}
        sx={{
          px: { xs: 2, md: 4 },
          alignItems: { xs: 'center', md: 'start' },
          background: '#fff',
          py: 3,
          height: { md: '80vh', xs: 'max-content' },
          maxHeight: '772px',
        }}
        direction="column"
        justifyContent="center"
        container
      >
        <Grid item>
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            fontFamily="Raleway"
            fontWeight="700"
            sx={{ textAlign: { xs: 'center', md: 'start' } }}
          >
            Promote Excursions and Earn Money as a CanAm Partner
          </Typography>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            LinkComponent={Link}
            to={RoutePaths.SIGN_UP}
            color="primary"
            disableElevation
            sx={{ px: 3 }}
          >
            Register Now
          </Button>
        </Grid>
      </Grid>
    </Grid>
  </Box>
);

export { AffiliatesBanner, AffiliateEarnMoneyBanner };
