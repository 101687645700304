// Application routes
const RoutePaths = {
  HOME: '/',
  SIGN_UP: '/signup',
  LOGIN: '/login',
  LOGOUT: '/logout',
  PAYMENTS: '/payments',
  SALES: '/sales',
  MANAGE_PROFILE: '/manage-profile',
  CREATIVES: '/creatives',
  RESET_PASSWORD: '/reset-password',
  FORGOT_PASSWORD: '/forgot-password',
  TERMS: 'https://canamexcursions.com/terms-and-conditions',
  PRIVACY: 'https://canamexcursions.com/privacy-policy',
  CONTACT_US: 'https://canamexcursions.com/contact-us',
  REZGO: 'https://canamexcursions.rezgo.com/details',
  USER_CART: 'https://canamexcursions.rezgo.com/order',
  ABOUT_US: 'https://canamexcursions.com/about-us',
  BLOG: 'https://canamexcursions.com/blogs/welcome-to-punta-cana', // use ID later
  FAQ: 'https://canamexcursions.com/faq',
  INVENTORY: 'https://canamexcursions.com/inventory',
};

export default RoutePaths;
