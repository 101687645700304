import { Container, Typography, Box } from '@mui/material';
import React from 'react';
import PaypalIcon from '../../assets/images/paypal_icon.svg';

const SecurePayment = () => (
  <Container
    sx={{
      background: '#fff',
      textAlign: 'center',
      my: 2,
      py: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Typography color="primary">Secure Payment Processing with</Typography>
    <Box pt={1}>
      <img src={PaypalIcon} alt="paypal-icon" width={42} height={42} />
    </Box>
  </Container>
);

export default SecurePayment;
