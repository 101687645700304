import React, { useEffect, useState } from 'react';
import {
  Container,
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  MenuItem,
  Paper,
  CircularProgress,
  Alert,
  Autocomplete,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm, Controller } from 'react-hook-form';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import PrivateWrapper from '../layouts/Private';
import AuthStyles from '../styles/views/Auth';
import { showErrorToastr, showSuccessToastr } from '../store/actions/toastrActions';
import Validations from '../utils/Validations';
import { getProfileData, saveProfileData } from '../services/Affiliate';
import RoutePaths from '../configs/Routes';
import { COUNTRIES } from '../configs/Constants';

const useStyles = makeStyles(AuthStyles);

const ManageProfile = () => {
  const classes = useStyles();
  const { control, handleSubmit, setValue } = useForm({ mode: 'onChange' });
  const [isLoading, setIsLoading] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [showPaypalSetupWarning, setShowPaypalSetupWarning] = useState(false);

  const onSubmit = async (data, e) => {
    try {
      e.preventDefault();

      const toSubmitData = {
        paypalCustomerEmail: data.paypalEmail,
        firstName: data.firstName,
        lastName: data.lastName,
        company: data.company,
        country: data.country ? data?.country?.label || '' : '',
        phoneNumber: data.phone,
        description: data.tellUsAboutYourself,
        type: data.type,
        facebookLink: data.facebook,
        instagramLink: data.instagram,
        tiktokLink: data.tiktok,
        websiteLink: data.website,
      };
      await saveProfileData(toSubmitData);
      showSuccessToastr('Profile updated sucessfully.');
      setReloadData(!reloadData);
    } catch (err) {
      showErrorToastr('Something Went Wrong!');
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setShowPaypalSetupWarning(false);
    getProfileData()
      .then((d) => {
        setValue('firstName', d?.firstName || '');
        setValue('lastName', d?.lastName || '');
        setValue('email', d?.email || '');
        setValue('phone', d?.phoneNumber || '');
        setValue('company', d?.company || '');
        if (d?.country) {
          const countryOption = COUNTRIES.filter((c) => c.label === d?.country);
          if (countryOption.length > 0) {
            setValue('country', countryOption[0]);
          } else {
            setValue('country', '');
          }
        } else {
          setValue('country', '');
        }
        setValue('paypalEmail', d?.paypalCustomerEmail || '');
        setValue('type', d?.type || '');
        setValue('facebook', d?.facebookLink || '');
        setValue('tellUsAboutYourself', d?.description || '');
        setValue('instagram', d?.instagramLink || '');
        setValue('tiktok', d?.tiktokLink || '');
        setValue('website', d?.websiteLink || '');
        if (!d?.paypalCustomerEmail) {
          setShowPaypalSetupWarning(true);
        }
        setIsLoading(false);
      })
      .catch(() => {
        showErrorToastr('Something Went Wrong!');
        window.location.assign(RoutePaths.HOME);
      });
  }, [reloadData]);

  return (
    <PrivateWrapper>
      <Paper elevation={0}>
        <Box
          minHeight="80vh"
          py={3}
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          {isLoading && (
            <Box display="flex" gap={2} p={2}>
              <CircularProgress size={28} />
              <Typography>Loading...</Typography>
            </Box>
          )}
          {!isLoading && (
            <Container>
              <Grid container width="100%">
                <Grid item xs={12}>
                  <Typography variant="h5" my={2} fontWeight="bold">
                    Edit Profile
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Container maxWidth="md">
                    {showPaypalSetupWarning && (
                      <Grid item xs={12}>
                        <Box maxWidth="md" mb={3}>
                          <Alert severity="error">
                            Your paypal email is not setup currently. Please update the paypal email
                            to receive payments.
                          </Alert>
                        </Box>
                      </Grid>
                    )}
                    <Box className={classes.formWrapper}>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={3} sx={{ mb: 3 }}>
                          <Grid item md={6} xs={12}>
                            <Controller
                              control={control}
                              id="firstName"
                              name="firstName"
                              rules={{ ...Validations.REQUIRED }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  required
                                  id="firstName"
                                  name="firstName"
                                  label="First Name"
                                  fullWidth
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error?.message : null}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Controller
                              control={control}
                              id="lastName"
                              name="lastName"
                              rules={{ ...Validations.REQUIRED }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  required
                                  id="lastName"
                                  name="lastName"
                                  label="Last Name"
                                  fullWidth
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error?.message : null}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Controller
                              control={control}
                              id="email"
                              name="email"
                              rules={{ ...Validations.EMAIL_REQUIRED }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  required
                                  id="email"
                                  fullWidth
                                  disabled
                                  name="email"
                                  label="Email address"
                                  type="email"
                                  value={value}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error?.message : null}
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Controller
                              id="phone"
                              name="phone"
                              control={control}
                              rules={{ validate: matchIsValidTel }}
                              render={({ field, fieldState: { error, invalid } }) => (
                                <MuiTelInput
                                  {...field}
                                  required
                                  fullWidth
                                  label="Phone Number"
                                  error={!!error || invalid}
                                  helperText={
                                    error || invalid
                                      ? error?.message || 'Invalid Phone Number'
                                      : null
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Controller
                              control={control}
                              id="company"
                              name="company"
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  id="company"
                                  fullWidth
                                  name="company"
                                  label="Company"
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error?.message : null}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Controller
                              control={control}
                              name="country"
                              id="country"
                              rules={{ ...Validations.REQUIRED }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Autocomplete
                                  name="country"
                                  id="country"
                                  options={COUNTRIES}
                                  autoHighlight
                                  onChange={(e, newValue) => {
                                    onChange(e);
                                    setValue('country', newValue);
                                  }}
                                  onInputChange={(event, newInputValue) => {
                                    setValue('country', newInputValue);
                                  }}
                                  value={value}
                                  getOptionLabel={(option) => option.label}
                                  renderOption={(props, option) => (
                                    <Box
                                      component="li"
                                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                      {...props}
                                    >
                                      <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        alt=""
                                      />
                                      {option.label} ({option.code})
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      required
                                      error={!!error}
                                      label="Choose a country"
                                      helperText={error ? error?.message : null}
                                    />
                                  )}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Controller
                              control={control}
                              id="paypalEmail"
                              name="paypalEmail"
                              rules={{ ...Validations.EMAIL_REQUIRED }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  required
                                  id="paypalEmail"
                                  fullWidth
                                  name="paypalEmail"
                                  label="PayPal Email Address"
                                  type="email"
                                  value={value}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error?.message : null}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Controller
                              control={control}
                              id="type"
                              name="type"
                              rules={Validations.REQUIRED}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  margin="dense"
                                  label="Type"
                                  select
                                  fullWidth
                                  value={value}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error?.message : null}
                                >
                                  <MenuItem value="Influencer">Influencer</MenuItem>
                                  <MenuItem value="Agent">Agent</MenuItem>
                                  <MenuItem value="Owner">Owner</MenuItem>
                                </TextField>
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Controller
                              control={control}
                              id="tellUsAboutYourself"
                              name="tellUsAboutYourself"
                              rules={{ ...Validations.REQUIRED }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  required
                                  id="tellUsAboutYourself"
                                  name="tellUsAboutYourself"
                                  label="Tell us about yourself"
                                  fullWidth
                                  multiline
                                  minRows={11}
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error?.message : null}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Controller
                              control={control}
                              id="facebook"
                              name="facebook"
                              rules={{ ...Validations.VALIDATE_URL }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  id="facebook"
                                  fullWidth
                                  name="facebook"
                                  label="Facebook"
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error?.message : null}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Controller
                              control={control}
                              id="instagram"
                              name="instagram"
                              rules={{ ...Validations.VALIDATE_URL }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  id="instagram"
                                  fullWidth
                                  name="instagram"
                                  label="Instagram"
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error?.message : null}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Controller
                              control={control}
                              id="tiktok"
                              name="tiktok"
                              rules={{ ...Validations.VALIDATE_URL }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  id="tiktok"
                                  fullWidth
                                  name="tiktok"
                                  label="Tiktok"
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error?.message : null}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Controller
                              control={control}
                              id="website"
                              name="website"
                              rules={{ ...Validations.VALIDATE_URL }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  id="website"
                                  fullWidth
                                  name="website"
                                  label="Website"
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error?.message : null}
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Button variant="contained" fullWidth type="submit" disableElevation>
                              Submit
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </Box>
                  </Container>
                </Grid>
              </Grid>
            </Container>
          )}
        </Box>
      </Paper>
    </PrivateWrapper>
  );
};

export default ManageProfile;
