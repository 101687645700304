/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import { makeStyles, useTheme } from '@mui/styles';
import { List, Button, Drawer, useMediaQuery, Stack } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { Menu as MenuIcon } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { onAuthStateChanged } from '@firebase/auth';
import RoutePaths from '../../configs/Routes';
import Logo from '../../assets/images/logo_blue.png';
import {
  handleCloseMainNavbarDrawer,
  handleMainNavbarDrawerToggle,
  handleSidebarDrawerToggle,
} from '../../store/actions/drawerActions';
import UserMainMenu from './UserMainMenu';
import { auth } from '../../services/Auth';

const useStyles = makeStyles((theme) => ({
  logoImage: {
    height: '60px',
    width: '120px',
    [theme.breakpoints.down('sm')]: {
      width: '110px',
      height: '60px',
    },
  },
  socialIcon: {
    [theme.breakpoints.down('md')]: {
      height: '24px',
      width: '24px',
    },
  },
  icon: {
    height: 'auto !important',
    width: 'auto !important',
  },
  navLink: {
    padding: '0 16px',
    fontWeight: 'normal !important',
    fontSize: '16px !important',
    lineHeight: '19px !important',
    color: ' rgba(0, 0, 0, 0.75)',
    textDecoration: 'none', // remove default underline
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&.active': {
      // add styles for active NavLink
      color: `${theme.palette.primary.main} !important`,
    },
    '&.hover': {
      // add styles for active NavLink
      color: `${theme.palette.primary.main} !important`,
    },
    [theme.breakpoints.down('md')]: {
      fontWeight: '700 !important',
      fontSize: '18px !important',
      lineHeight: '29px !important',
    },
  },
  activeLink: {
    padding: '0 16px',
    fontWeight: '700 !important',
    fontSize: '16px !important',
    lineHeight: '19px !important',
    textDecoration: 'none', // remove default underline
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: `${theme.palette.primary.main} !important`,
  },
}));

const NavbarContent = ({ mdUp }) => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // TODO: add original auth state later

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, handle authenticated state
        // You can access the user object for further processing

        setLoggedIn(true);
      } else {
        // User is signed out, handle unauthenticated state

        setLoggedIn(false);
      }
    });
    unsubscribe();
  }, []);
  const { pathname } = useLocation();

  return (
    <>
      {!mdUp && (
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            if (location.pathname === RoutePaths.SIGN_UP || !isLoggedIn) {
              handleMainNavbarDrawerToggle();
            } else handleSidebarDrawerToggle();
          }}
          sx={{ alignSelf: 'end' }}
        >
          <HighlightOffIcon />
        </IconButton>
      )}
      <List component={Stack} direction={mdUp ? 'row' : 'column'} sx={{ gap: { xs: 3, md: 1 } }}>
        {/* <ListItemStyle
          to={RoutePaths.HOME}
          selected={pathname === RoutePaths.HOME}
          disablePadding
          disableTouchRipple
          disableRipple
          onClick={handleCloseMainNavbarDrawer}
        >
          Home
        </ListItemStyle>

        <ListItemStyle
          to={RoutePaths.BLOG}
          selected={pathname === RoutePaths.BLOG}
          disablePadding
          onClick={handleCloseMainNavbarDrawer}
        >
          Blogs
        </ListItemStyle>
        <ListItemStyle
          selected={pathname === RoutePaths.ABOUT_US}
          to={RoutePaths.ABOUT_US}
          disablePadding
          onClick={handleCloseMainNavbarDrawer}
        >
          About Us
        </ListItemStyle> */}
        {!isLoggedIn ? (
          mdUp ? (
            <Box
              display="flex"
              gap={2}
              sx={{ px: '16px' }}
              alignItems="center"
              flexDirection="row-reverse"
            >
              {pathname !== RoutePaths.SIGN_UP && (
                <Button
                  sx={{ height: 'fit-content', padding: '6px 16px' }}
                  LinkComponent={Link}
                  to={RoutePaths.SIGN_UP}
                  variant="contained"
                  disableElevation
                  onClick={handleCloseMainNavbarDrawer}
                >
                  Become affiliate
                </Button>
              )}
              <Button
                sx={{ height: 'fit-content', padding: '6px 16px' }}
                LinkComponent={Link}
                to={RoutePaths.LOGIN}
                variant="outlined"
                disableElevation
                onClick={handleCloseMainNavbarDrawer}
              >
                Affiliate Login
              </Button>
            </Box>
          ) : (
            <Box
              display="flex"
              gap={2}
              sx={{ px: '16px', pt: '18px' }}
              flexDirection="column-reverse"
            >
              {pathname !== RoutePaths.SIGN_UP && (
                <Button
                  fullWidth
                  LinkComponent={Link}
                  to={RoutePaths.SIGN_UP}
                  variant="contained"
                  disableElevation
                  sx={{ height: 'fit-content', padding: '12px 16px' }}
                  onClick={handleCloseMainNavbarDrawer}
                >
                  Become affiliate
                </Button>
              )}
              <Button
                fullWidth
                LinkComponent={Link}
                to={RoutePaths.LOGIN}
                variant="outlined"
                disableElevation
                sx={{ height: 'fit-content', padding: '12px 16px' }}
                onClick={handleCloseMainNavbarDrawer}
              >
                Affiliate Login
              </Button>
            </Box>
          )
        ) : (
          <UserMainMenu forMobile={!mdUp} />
        )}
      </List>
    </>
  );
};

NavbarContent.propTypes = {
  mdUp: PropTypes.bool.isRequired,
};

const DrawerNav = ({ isNavbarDrawerOpen, toggleNavbarDrawer, children }) => (
  <Drawer
    PaperProps={{
      sx: { height: '100vh', p: '30px 20px' },
    }}
    anchor="top"
    open={isNavbarDrawerOpen}
    onClose={toggleNavbarDrawer}
  >
    {children}
  </Drawer>
);

DrawerNav.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
  isNavbarDrawerOpen: PropTypes.bool.isRequired,
  toggleNavbarDrawer: PropTypes.func.isRequired,
};

const Navbar = ({ socialsOnly, open, toggleNav }) => {
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const mdUp = useMediaQuery(theme.breakpoints.up('lg'));
  const [isLoggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    // TODO: add original auth state later

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, handle authenticated state
        // You can access the user object for further processing

        setLoggedIn(true);
      } else {
        // User is signed out, handle unauthenticated state

        setLoggedIn(false);
      }
    });
    unsubscribe();
  }, []);

  return (
    <>
      <AppBar position="sticky" color="inherit" component={Box}>
        <Toolbar component={Container}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            disableRipple
            sx={{
              mr: 'auto',
            }}
            onClick={() => {
              window.location.assign(RoutePaths.HOME);
            }}
          >
            <img src={Logo} className={classes.logoImage} alt="CanAmExcursion-logo" />
          </IconButton>

          {!mdUp && !socialsOnly && (
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                if (location.pathname === RoutePaths.SIGN_UP || !isLoggedIn) {
                  handleMainNavbarDrawerToggle();
                } else handleSidebarDrawerToggle();
              }}
            >
              <MenuIcon />
            </IconButton>
          )}

          {(mdUp || socialsOnly) && <NavbarContent socialsOnly={socialsOnly} mdUp={mdUp} />}
          {!mdUp && !socialsOnly && (
            <DrawerNav isNavbarDrawerOpen={open} toggleNavbarDrawer={toggleNav}>
              <NavbarContent socialsOnly={socialsOnly} mdUp={mdUp} toggleNav={toggleNav} />
            </DrawerNav>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

Navbar.propTypes = {
  open: PropTypes.bool.isRequired,
  socialsOnly: PropTypes.bool.isRequired,
  toggleNav: PropTypes.func.isRequired,
};

export default Navbar;
