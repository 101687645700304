import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import ErrorWrapper from '../layouts/ErrorWrapper';
import RoutePaths from '../configs/Routes';

const NotFound = () => (
  <ErrorWrapper>
    <Box
      sx={{ py: { xs: 20, md: 30 } }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        variant="h1"
        align="center"
        sx={{
          fontWeight: 700,
          color: 'rgba(0, 0, 0, 0.75)',
          fontSize: { xs: '35px', md: '60px' },
          lineHeight: { xs: '40px', md: '72px' },
        }}
      >
        The page you&apos;re looking for can&apos;t be found.
      </Typography>
      <Button
        variant="contained"
        LinkComponent={Link}
        to={RoutePaths.HOME}
        sx={{
          p: { xs: 2, md: 3 },
          minWidth: { xs: '300px', md: '400px' },
          my: 3,
        }}
      >
        Visit our homepage
      </Button>
    </Box>
  </ErrorWrapper>
);

export default NotFound;
