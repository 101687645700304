import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import resets from './_resets.module.css';
import classes from './flyer.module.css';
import CanadaMapleLeafSvgrepoCom2Icon from './CanadaMapleLeafSvgrepoCom2Icon';
import EtPhoneIcon from './EtPhoneIcon';
import SafetySvgrepoCom1Icon from './SafetySvgrepoCom1Icon';
import HandIcon from './Hand';

export const FlyerOne = ({ flyerRef, promoLink }) => (
  <div ref={flyerRef} className={`${resets.storybrainResets} ${classes.root}`}>
    <div className={classes.rectangle20} />
    <div className={classes.rectangle15} />
    <div className={classes.rectangle5} />
    <div className={classes.qrCodeCanamexcurions1}>
      <div
        className={classes.frame}
        style={{ background: 'white', border: '1px solid #E18840', padding: '26.5px' }}
      >
        <QRCode value={promoLink} style={{ height: '100%', width: '100%' }} />
      </div>
    </div>
    <div className={classes.sCANME}>SCAN ME</div>
    <div className={classes.etPhone}>
      <EtPhoneIcon className={classes.icon2} />
    </div>
    <div className={classes.puntaCana}>Punta Cana</div>
    <div className={classes.excursions}>Excursions</div>
    <div className={classes.rectangle13} />
    <div className={classes.rectangle16} />
    <div className={classes.rectangle17} />
    <div className={classes.aBOUTUS}>ABOUT US</div>
    <div className={classes.wHYCHOOSEUS}>WHY CHOOSE US?</div>
    <div className={classes.poremIpsumDolorSitAmetConsecte}>
      CanAm Excursions is a Canadian-based company that offers safe and thrilling tours for Canadian
      and American travellers. Book now and let the adventure begin!
    </div>
    <div className={classes.frame1}>
      <div className={classes.rectangle19} />
      <div className={classes.rectangle132} />
      <div className={classes.rectangle18} />
    </div>
    <div className={classes.yOURSAFETYOURPRIORITYPoremIpsu}>
      <div className={classes.textBlock}>YOUR SAFETY IS OUR PRIORITY</div>
      <div className={classes.textBlock2}>
        <p className={classes.labelWrapper}>
          <span className={classes.label}>
            All tours, guides, and activities are vetted for safety and quality.
          </span>
        </p>
      </div>
    </div>
    <div className={classes.yOURBOOKINGISGUARANTEEDPoremIp}>
      <div className={classes.textBlock3}>BOOKING GUARANTEE</div>
      <div className={classes.textBlock4}>
        <p className={classes.labelWrapper2}>
          <span className={classes.label2}>Once you book an activity, it is reserved for you.</span>
        </p>
      </div>
    </div>
    <div className={classes.tRUSTEDBYCANADIANSAMERICANSPor}>
      <div className={classes.textBlock5}>TRUSTED BY CANADIANS &amp; AMERICANS</div>
      <div className={classes.textBlock6}>
        <p className={classes.labelWrapper3}>
          <span className={classes.label3}>We are your Canadian connection to the Caribbean.</span>
        </p>
      </div>
    </div>
    <div className={classes.canadaMapleLeafSvgrepoCom3}>
      <HandIcon className={classes.icon3} />
    </div>
    <div className={classes.canadaMapleLeafSvgrepoCom2}>
      <CanadaMapleLeafSvgrepoCom2Icon className={classes.icon3} />
    </div>
    <div className={classes.safetySvgrepoCom1}>
      <SafetySvgrepoCom1Icon className={classes.icon4} />
    </div>
    <div className={classes.wwwCanamexcursionsCom}>www.canamexcursions.com</div>
    {/* <div className={classes.frame2}>
      <div className={classes.filledWithThrillAdventures}>Filled with Thrill &amp; Adventures</div>
    </div> */}
    <div className={classes.cANAMLOGOWhitee1} />
    <div className={classes.dOMINICANREPUBLIC}>DOMINICAN REPUBLIC</div>
    <div className={classes.rectangle21} />
  </div>
);

FlyerOne.propTypes = {
  flyerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  promoLink: PropTypes.string.isRequired,
};

export default FlyerOne;
