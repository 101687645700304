import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();
  const [prevPathname, setPrevPathname] = useState(null);

  useEffect(() => {
    if (prevPathname !== pathname) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      setPrevPathname(pathname);
    }
  }, [pathname, prevPathname]);

  return null;
}

export default ScrollToTop;
