import React from 'react';
import { Grid, Typography, Box, Container } from '@mui/material';

const HowItWorks = () => {
  const data = [
    {
      title: 'Register',
      boxColor: '#E1884080',
      info: 'Fill out a short form on our website.',
    },
    {
      title: 'Application Review',
      boxColor: '#0074B580',
      info: 'CanAm reviews your application.',
    },
    {
      title: 'Get Affiliate Link',
      boxColor: '#00000080',
      info: "Once approved, you'll get a unique affiliate code to track referrals.",
    },
    {
      boxColor: '#002C4480',
      title: 'Earn Money',
      info: 'Start promoting! Earn commission for each successful referral.',
    },
  ];
  return (
    <Box component={Container} my={3} py={4} bgcolor="#fff">
      <Box width="max-content" mx="auto">
        <Typography
          variant="h4"
          component="h4"
          color="#002C44"
          textAlign="center"
          sx={{
            width: 'fit-content',
            borderBottom: '1px solid #023C684D',
            paddingBottom: 1,
          }}
          fontFamily="Raleway"
          fontWeight="400"
        >
          How it works
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ paddingTop: 2 }}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={data.title}>
            <Box display="flex" flexDirection="column" alignItems="center" p={2}>
              <Box
                width={64}
                height={64}
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor={item.boxColor}
                borderRadius="16px"
                marginBottom={1}
                color="#fff"
              >
                <Typography variant="subtitle1" align="center" fontWeight="bold">
                  {index + 1}
                </Typography>
              </Box>
              <Typography variant="subtitle1" align="center" color="#000000BF" fontWeight="bold">
                {item.title}
              </Typography>
              <Typography align="center" fontSize="16px" color="#000000BF">
                {item.info}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HowItWorks;
