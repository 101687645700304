// List of validations
const Validations = {
  REQUIRED: {
    required: {
      value: true,
      message: 'This field is required.',
    },
  },
  REQUIRED_SHORT_MSG: {
    required: {
      value: true,
      message: 'Required.',
    },
  },
  EMAIL_REQUIRED: {
    required: {
      value: true,
      message: 'This field is required.',
    },
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'Please enter a valid email address.',
    },
  },
  PASSWORD_REQUIRED: {
    required: {
      value: true,
      message: 'This field is required.',
    },
    minLength: {
      value: 8,
      message: 'Password must be at least 8 characters long.',
    },
    pattern: {
      value: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      message: 'Password must contain at least one letter, one number, and one symbol.',
    },
  },
  VALIDATE_URL: {
    pattern: {
      value:
        /^(?:(?:https?):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
      message: 'Please enter a valid URL.',
    },
  },
};

export default Validations;
