import React, { useEffect, useState } from 'react';
import {
  Link,
  Container,
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useForm, Controller } from 'react-hook-form';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Validations from '../../utils/Validations';
import { showErrorToastr, showSuccessToastr } from '../../store/actions/toastrActions';
import AuthStyles from '../../styles/views/Auth';
import RoutePaths from '../../configs/Routes';
import LoginPageBG from '../../assets/images/login_bg.png';
import LogoImg from '../../assets/images/logo_blue.png';
import pageBGsm from '../../assets/images/auth_bg_sm.png';
import { logInWithEmailAndPassword } from '../../services/Auth';

const useStyles = makeStyles(AuthStyles);

const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { control, handleSubmit } = useForm();

  const [showPassword, setShowPassword] = useState(false);

  const emailLogin = async (data) => {
    let result = { item: [] };
    result = await logInWithEmailAndPassword(`${data.email}`, `${data.password}`);
    return result;
  };

  const SetCookie = (key, value) => {
    Cookies.set(key, value, {
      expires: 7,
    });
  };

  const onSubmit = async (data) => {
    try {
      const user = await emailLogin(data);
      if (!(await user.success)) {
        showErrorToastr(user?.message || 'Incorrect Combination of Email & Password');
        navigate(RoutePaths.LOGIN);
      } else {
        showSuccessToastr(user.message);
        SetCookie('token', user.data.uid);
        SetCookie('email', user.data.email);
        SetCookie('name', user.data.name);
        SetCookie('refId', user.data.refId);

        navigate(RoutePaths.HOME);
      }
    } catch ({ response }) {
      showErrorToastr(typeof response === 'string' ? response : 'Something Went Wrong!');
    }
  };

  const GetCookie = (key) => Cookies.get(key);

  useEffect(() => {
    const token = GetCookie('refId');
    if (token !== undefined) {
      navigate(RoutePaths.HOME);
    }
  }, []);

  return (
    <Grid container component="main" spacing={0} sx={{ alignItems: mdUp ? 'auto' : 'center' }}>
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={7}
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundImage: mdUp ? `url(${LoginPageBG})` : `url(${pageBGsm})`,
          minHeight: !mdUp ? 300 : 'auto',
        }}
        className={classes.formImg}
      >
        {!mdUp && (
          <Box
            className={classes.logoImg}
            sx={{
              m: 0,
              backgroundImage: `url(${LogoImg})`,
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={5}>
        <Container maxWidth="sm">
          <Box className={classes.formWrapper}>
            {mdUp && (
              <Box
                className={classes.logoImg}
                sx={{
                  backgroundImage: `url(${LogoImg})`,
                }}
              />
            )}
            <Typography variant="h4" className={classes.formTitle} gutterBottom>
              Welcome Back!
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3} sx={{ mb: 3 }}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    id="email"
                    name="email"
                    rules={{ ...Validations.EMAIL_REQUIRED }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        required
                        id="email"
                        fullWidth
                        name="email"
                        className={classes.formInput}
                        label="Email address"
                        type="email"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error?.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    id="password"
                    name="password"
                    rules={{ ...Validations.REQUIRED }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        name="password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error?.message : null}
                        className={classes.formInput}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    className={classes.formBtnText}
                  >
                    Login
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              <Link href={RoutePaths.RESET_PASSWORD} underline="hover">
                Forgot Password?
              </Link>
            </Typography>
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account yet?{' '}
              <Link href={RoutePaths.SIGN_UP} underline="hover">
                Sign Up
              </Link>
            </Typography>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Login;
