import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Typography,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { Download } from '@mui/icons-material';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import PrivateWrapper from '../layouts/Private';
import { showErrorToastr, showSuccessToastr } from '../store/actions/toastrActions';
import RoutePaths from '../configs/Routes';
import { getProfileData } from '../services/Affiliate';
import TableSkeleton from '../components/common/TableSkeleton';
import { FlyerOne } from '../components/affiliate-flyer/Flyers';

const Creatives = () => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [promoLink, setPrmomoLink] = useState();
  const [generatingPDF, setGeneratingPDF] = useState(false);

  const flyerOneRef = useRef();
  const flyers = [{ name: 'Flyer 1', template: 1 }];

  const columns = [
    { name: 'Promolink', id: 'prmolink', width: '55%' },
    { name: 'Flyer', id: 'flyer', width: '25%' },
    { name: 'Download', id: 'flyer', width: '5%' },
  ];

  useEffect(() => {
    setDataLoaded(false);
    setGeneratingPDF(false);

    getProfileData()
      .then((d) => {
        if (d?.promocodes && d?.promocodes.length > 0) {
          setPrmomoLink(`https://canamexcursions.rezgo.com?promo=${d.promocodes[0]}` || '-');
          setDataLoaded(true);
        } else {
          showErrorToastr('Something Went Wrong!');
          window.location.assign(RoutePaths.HOME);
          setDataLoaded(true);
        }
      })
      .catch(() => {
        showErrorToastr('Something Went Wrong!');
        window.location.assign(RoutePaths.HOME);
      });
  }, []);

  const handleDownload = (template) => {
    if (template === 1) {
      setGeneratingPDF(true);
      html2canvas(flyerOneRef.current, { scale: 1 })
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/jpeg', 1.0);
          // eslint-disable-next-line new-cap
          const pdf = new jsPDF();

          const width = pdf.internal.pageSize.getWidth();
          const height = pdf.internal.pageSize.getHeight();

          pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
          pdf.save(`Flyer-${template}.pdf`);
          setGeneratingPDF(false);
          showSuccessToastr('PDF downloaded sucessfully.');
        })
        .catch(() => {
          showErrorToastr('Something went wrong while downloading PDF.');
          setGeneratingPDF(false);
        });
    }
  };

  return (
    <PrivateWrapper>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={generatingPDF}
      >
        <CircularProgress sx={{ color: '#fff' }} />
      </Backdrop>
      <Paper elevation={0}>
        <Box minHeight="80vh" p={3} flexDirection="column" width="100%" display="flex">
          <Typography variant="h5" my={2} fontWeight="bold">
            Flyers
          </Typography>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead sx={{ background: '#f0f0f0' }}>
                <TableRow>
                  {columns.map((col) => (
                    <TableCell
                      sx={{ fontWeight: 'bold' }}
                      width={col.width}
                      align={col.align || 'left'}
                      key={`col-${col.id}`}
                    >
                      {col.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!dataLoaded && <TableSkeleton cols={3} />}
                {dataLoaded && flyers && flyers.length === 0 && (
                  <TableCell align="center" size="medium" colSpan={3} sx={{ color: 'red' }}>
                    No records found
                  </TableCell>
                )}
                {dataLoaded &&
                  flyers &&
                  flyers.map((row) => (
                    <TableRow key={`sb-${row.name}`}>
                      <TableCell>{promoLink}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() => {
                            handleDownload(row.template);
                          }}
                        >
                          <Download />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box position="relative" overflow="hidden">
            <Box position="absolute" top={-99999}>
              <FlyerOne flyerRef={flyerOneRef} promoLink={promoLink} />
            </Box>
          </Box>
        </Box>
      </Paper>
    </PrivateWrapper>
  );
};

export default Creatives;
