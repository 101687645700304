import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Box, CircularProgress, Container, Typography } from '@mui/material';

import PublicWrapper from '../../layouts/Public';
import { logout } from '../../services/Auth';
import RoutePaths from '../../configs/Routes';

const Logout = () => {
  const navigate = useNavigate();
  const remove = async () => {
    await logout();

    // Clear all cookies
    document.cookie.split(';').forEach((cookie) => {
      document.cookie = cookie
        .replace(/^ +/, '')
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });

    // Clear local storage
    localStorage.clear();

    // Clear session storage
    sessionStorage.clear();

    navigate(RoutePaths.HOME);
  };

  useEffect(() => {
    remove();
  }, []);
  return (
    <PublicWrapper socialsOnly>
      <Box
        container
        spacing={0}
        style={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="xs">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="inherit" size={24} sx={{ mr: 1 }} />
            <Typography component="p">Logging Out.</Typography>
          </div>
        </Container>
      </Box>
    </PublicWrapper>
  );
};

export default Logout;
