import React from 'react';
import { Grid, Typography, Box } from '@mui/material';

const AffiliateBenefits = () => {
  const data = [
    {
      title: 'EASY ONBOARDING',
      bgColor: '#fff',
      color: '#E18840',
      info: `We'll get you set up and ready to go in no time!`,
    },
    {
      title: 'MONTHLY PAYOUTS',
      bgColor: '#EBF8FF',
      color: '#0074B5',
      info: `We're committed to paying our affiliates promptly for their hard work.`,
    },
    {
      title: 'NO MINIMUM PAY-OUT THRESHOLD',
      bgColor: '#EBF8FF',
      color: '#0074B5',
      info: `You don't have to wait until you reach a certain threshold to receive your payment.`,
    },
    {
      title: 'INSTANT DASHBOARD ACCESS',
      bgColor: '#fff',
      color: '#E18840',
      info: 'Join the CanAm Excursions Affiliate Program today and start earning money for sharing your love of travel with others!',
    },
  ];
  return (
    <Box my={3}>
      <Box width="max-content" mx="auto">
        <Typography
          variant="h4"
          color="#002C44"
          textAlign="center"
          sx={{
            width: 'fit-content',
            borderBottom: '1px solid #023C684D',
            paddingBottom: 1,
          }}
          fontFamily="Raleway"
          fontWeight="400"
        >
          Affiliation Benefits
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ paddingTop: 2 }}>
        {data.map((item) => (
          <Grid item xs={12} md={6} key={data.title}>
            <Box display="flex" flexDirection="column" bgcolor={item.bgColor} p={2}>
              <Typography
                variant="subtitle1"
                textAlign="left"
                color={item.color}
                sx={{
                  width: 'fit-content',
                  borderBottom: '1px solid #023C684D',
                  paddingBottom: 1,
                  mb: 2,
                }}
                fontFamily="Raleway"
                fontWeight="700"
              >
                {item.title}
              </Typography>
              {/* <Typography variant="body1" textAlign="left" fontSize="16px" color="#000000BF">
                {item.info}
              </Typography> */}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AffiliateBenefits;
