import React from 'react';

const EtPhoneIcon = () => (
  <svg
    preserveAspectRatio="none"
    viewBox="0 0 24 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7_662)">
      <path
        d="M1.89474 40H22.1053C23.1499 40 24 39.1588 24 38.125V1.875C24 0.84125 23.1499 0 22.1053 0H1.89474C0.850105 0 0 0.84125 0 1.875V38.125C0 39.1588 0.850105 40 1.89474 40ZM1.26316 1.875C1.26316 1.70924 1.3297 1.55027 1.44814 1.43306C1.56659 1.31585 1.72723 1.25 1.89474 1.25H22.1053C22.2728 1.25 22.4334 1.31585 22.5519 1.43306C22.6703 1.55027 22.7368 1.70924 22.7368 1.875V38.125C22.7368 38.2908 22.6703 38.4497 22.5519 38.5669C22.4334 38.6842 22.2728 38.75 22.1053 38.75H1.89474C1.72723 38.75 1.56659 38.6842 1.44814 38.5669C1.3297 38.4497 1.26316 38.2908 1.26316 38.125V1.875Z"
        fill="white"
      />
      <path
        d="M3.15789 33.75H20.8421C21.0096 33.75 21.1703 33.6842 21.2887 33.5669C21.4071 33.4497 21.4737 33.2908 21.4737 33.125V6.875C21.4737 6.70924 21.4071 6.55027 21.2887 6.43306C21.1703 6.31585 21.0096 6.25 20.8421 6.25H3.15789C2.99039 6.25 2.82975 6.31585 2.7113 6.43306C2.59286 6.55027 2.52632 6.70924 2.52632 6.875V33.125C2.52632 33.2908 2.59286 33.4497 2.7113 33.5669C2.82975 33.6842 2.99039 33.75 3.15789 33.75ZM3.78947 7.5H20.2105V32.5H3.78947V7.5Z"
        fill="white"
      />
      <path
        d="M12.6316 37.5C13.3292 37.5 13.8947 36.9404 13.8947 36.25C13.8947 35.5596 13.3292 35 12.6316 35C11.934 35 11.3684 35.5596 11.3684 36.25C11.3684 36.9404 11.934 37.5 12.6316 37.5Z"
        fill="white"
      />
      <path
        d="M9.47368 5H14.5263C14.6938 5 14.8545 4.93415 14.9729 4.81694C15.0914 4.69973 15.1579 4.54076 15.1579 4.375C15.1579 4.20924 15.0914 4.05027 14.9729 3.93306C14.8545 3.81585 14.6938 3.75 14.5263 3.75H9.47368C9.30618 3.75 9.14554 3.81585 9.02709 3.93306C8.90865 4.05027 8.84211 4.20924 8.84211 4.375C8.84211 4.54076 8.90865 4.69973 9.02709 4.81694C9.14554 4.93415 9.30618 5 9.47368 5Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7_662">
        <rect width={24} height={40} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default EtPhoneIcon;
