// Style for landing pages
const Style = (theme) => ({
  bannerSection: {
    minHeight: `calc(100vh - 110px)`,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  bannerHeader: {
    fontFamily: `'Playfair Display', serif !important`,
    fontSize: '54px !important',
    fontWeight: 800,
    lineHeight: '40px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: '#fff',
    paddingBottom: '18px',
    [theme.breakpoints.down('md')]: {
      fontSize: '35px  !important',
      lineHeight: '45px !important',
    },
  },
  footerContainer: {
    zIndex: 100,
    background: 'rgba(0, 116, 181, 0.05)',
  },
  gridsSectionContainer: {
    padding: '60px',
  },
  gridSectionTitle: {
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: '58px',
    letterSpacing: '0em',
    textAlign: 'center',
    paddingBottom: 40,
    [theme.breakpoints.down('md')]: {
      fontSize: '35px  !important',
      lineHeight: '35px !important',
    },
  },
  bannerBody: {
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Lato',
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: '#fff',
    paddingBottom: '40px',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px  !important',
      lineHeight: '18px !important',
    },
  },
  offerTitle: {
    fontSize: '60px !important',
    fontWeight: '400 !important',
    lineHeight: '72px !important',
    letterSpacing: '0em !important',
    textAlign: 'center !important',
    color: `${theme.palette.primary.main} !important`,
    [theme.breakpoints.down('md')]: {
      fontSize: '35px  !important',
      lineHeight: '35px !important',
    },
  },
  offerBody: {
    fontWeight: '400px',
    fontSize: '30px',
    color: '#000',
    fontFamily: 'Lato',
    lineHeight: '32px !important',

    [theme.breakpoints.down('md')]: {
      fontSize: '16px  !important',
      textAlign: 'center !important',
    },
  },
  imgCard: {
    cursor: 'pointer',
    height: '100% !important',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 60.4%, #000000 100%), #D9D9D9 !important',
    borderRadius: '16px !important',
    [theme.breakpoints.down('md')]: {
      maxHeight: '500px',
      maxWidth: '400px',
      margin: 'auto',
    },
    transition: 'transform 0.2s ease-in-out',
    '&:hover, &:active': {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      transform: 'scale(1.05)',
    },
  },
  '@media (hover: none)': {
    imgCard: {
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.3)',
    },
  },

  excursionCardImg: {
    width: '100%',
    borderRadius: '16px !important',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 60.4%, #000000 100%), #D9D9D9 !important',
    objectFit: 'cover !important',
  },
  imgTitle: {
    fontWeight: '700',
    fontSize: '32px !important',
    lineHeight: '38px',
    paddingBottom: '10px !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '35px',
    },
  },
  imgTitleTypeCard: {
    fontWeight: '700',
    fontSize: '32px !important',
    lineHeight: '38px',
    paddingBottom: '20px !important',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '35px',
    },
  },
  imgBody: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    whiteSpace: 'pre-wrap',
    fontSize: '14px !important',
    fontWeight: '400 !important',
    lineHeight: '19px !important',
    letterSpacing: '0em',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px !important',
      fontWeight: '400 !important',
      lineHeight: '15px !important',
    },
    // maxWidth: 'calc(100% - 20px)', // add max-width to allow ellipsis to work
  },
  imgOverlay: {
    padding: 'auto 30px',
    borderRadius: '0 0 16px 16px',
    // background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 60.4%, #000000 100%),
    // linear-gradient(0deg, #D9D9D9, #D9D9D9)`,
  },
  footerLinkHeader: {
    padding: '0 !important',
    paddingBottom: '35px !important',
    fontWeight: '700 !important',
    fontSize: '16px !important',
    lineHeight: '15px !important',
    color: 'rgba(0, 0, 0, 0.75) !important',
    background: 'transparent !important',
    [theme.breakpoints.down('lg')]: {
      paddingBottom: '10px !important',
      marginTop: '20px !important',
    },
  },
  footerLinks: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '15px',
    color: 'rgba(0, 0, 0, 0.75) !important',
  },

  socialIcon: {
    [theme.breakpoints.down('md')]: {
      height: '24px',
      width: '24px',
    },
  },
  icon: {
    height: 'auto !important',
    fontSize: '35px !important',
    width: 'auto !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '24px  !important',
      lineHeight: '24px !important',
    },
  },
  trustSectionBody: {
    fontWeight: '400 !important',
    fontSize: '12px !important',
    lineHeight: '16px !important',
  },
  getStartedBtn: {
    fontSize: '18px !important',
    fontWeight: '700  !important',
    lineHeight: '20px  !important',
    maxWidth: '200px !important',
    padding: '12px 40px !important',

    color: `${theme.palette.primary.main} !important`,
    backgroundColor: '#fff !important',
    '&:focus': {
      outline: 'none !important',
    },
    [theme.breakpoints.down('md')]: {
      padding: '16px 24px !important',
      width: '200px',
    },
  },

  todaysDealBtn: {
    fontSize: '18px !important',
    fontWeight: '700  !important',
    lineHeight: '20px  !important',
    color: '#fff !important',
    borderColor: '#fff !important',
    maxWidth: '200px !important',
    '&:hover': {
      backgroundColor: '#fff !important',
      color: `${theme.palette.primary.main} !important`,
    },
    '&:active': {
      backgroundColor: '#fff !important',
      or: theme.palette.primary.main,
    },
    '&:focus': {
      outline: 'none !important',
    },
    padding: '12px 40px !important',
    [theme.breakpoints.down('md')]: {
      padding: '16px 24px !important',
      width: '200px',
    },
  },
});

export default Style;
