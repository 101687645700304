import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { hideToastr } from '../../store/reducers/toastrSlice';

function Toastr() {
  const { open, message, severity } = useSelector((state) => state.toastr);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideToastr());
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert elevation={6} variant="filled" severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default Toastr;
