import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { COLLECTIONS } from '../configs/Constants';
import { auth, db, getBalanceCommissionTotalSales } from './Auth';

export const getProfileData = async () => {
  const { currentUser } = auth;
  if (currentUser) {
    const userDocRef = doc(db, COLLECTIONS.USERS, currentUser.uid);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const uData = userDocSnap.data();
      const refDocRef = doc(db, COLLECTIONS.AFFILIATES, uData.ref_id);
      const refDocSnap = await getDoc(refDocRef);
      const mainData = refDocSnap.data();
      return mainData;
    }
    throw new Error('No User Found. Please Login to continue');
  } else {
    throw new Error('No User Found. Please Login to continue');
  }
};

export const saveProfileData = async (data) => {
  const { currentUser } = auth;

  if (currentUser) {
    const userDocRef = doc(db, COLLECTIONS.USERS, currentUser.uid);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const uData = userDocSnap.data();
      const refDocRef = doc(db, COLLECTIONS.AFFILIATES, uData.ref_id);
      await updateDoc(refDocRef, data);
    } else throw new Error('No User Found. Please Login to continue');
  } else {
    throw new Error('No User Found. Please Login to continue');
  }
};

export const getAgentOverview = async (id) => {
  try {
    const data = {
      balance: 100,
      commission: 2,
      sales: 22,
      promocode: null,
      approved: true,
    };
    // TODO: add overview api service

    const jsonData = await getBalanceCommissionTotalSales(id);

    data.balance = await jsonData.Balance;
    data.commission = await jsonData.commission;
    data.sales = await jsonData.total_sales_count;
    data.promocode = await jsonData.promocode;
    return data;
  } catch {
    const data = {
      approved: false,
    };
    return data;
  }
};
