import React from 'react';
import { Container, Button, TextField, Grid, Box, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import AffiliateAboutBanner from '../../assets/images/about_banner.png';
import AffiliateAboutFormBanner from '../../assets/images/home_about_section.png';
import Validations from '../../utils/Validations';
import AuthStyles from '../../styles/views/Auth';
import { storeContactUsDetails } from '../../services/Auth';
import { showErrorToastr, showSuccessToastr } from '../../store/actions/toastrActions';

const useStyles = makeStyles(AuthStyles);

const AboutUs = () => (
  <Box>
    <Box width="max-content" mx="auto">
      <Typography
        variant="h4"
        component="h4"
        color="#002C44"
        textAlign="center"
        sx={{
          width: 'fit-content',
          borderBottom: '1px solid #023C684D',
          paddingBottom: 1,
        }}
        fontFamily="Raleway"
        fontWeight="400"
      >
        About Us
      </Typography>
    </Box>
    <Box
      sx={{
        height: '60vh',
        maxHeight: '450px !important',
        backgroundImage: `url(${AffiliateAboutBanner})`,
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'start',
        backgroundSize: 'cover',
        display: { xs: 'none', md: 'block' },
        my: 3,
      }}
    />
    <Box my={3}>
      <Grid container rowSpacing={1} spacing={2} component={Box}>
        <Grid item xs={12} sm={12} md={6}>
          <Typography fontWeight="300" sx={{ display: { xs: 'none', md: 'block' } }} variant="h4">
            About Us
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Typography variant="subtitle1" fontWeight="300" pt={1}>
            CanAm Excursions is a Canadian-based tour provider offering safe and thrilling cultural
            experiences in Punta Cana for Canadian and American travellers.
          </Typography>
          <Typography fontWeight="300" variant="subtitle1" py={1}>
            We prioritize safety by partnering with pre-screened tour operators and always have an
            English and Spanish-speaking representative present to alleviate language barriers for
            clear communication.
          </Typography>
          <Typography fontWeight="300" variant="subtitle1" pb={1}>
            Book now and let the adventure begin!
          </Typography>
        </Grid>
      </Grid>
    </Box>
    <AboutUsForm />
  </Box>
);

const AboutUsForm = () => {
  const classes = useStyles();

  const { control, handleSubmit } = useForm({ mode: 'onChange' });
  const onSubmit = async (data) => {
    // eslint-disable-next-line no-console
    try {
      const toSubmitData = {
        ...data,
      };
      await storeContactUsDetails(toSubmitData);
      showSuccessToastr('Message sent successfully.');
      window.location.reload();
    } catch ({ response }) {
      showErrorToastr(typeof response === 'string' ? response : 'Something Went Wrong!');
    }
    // TODO: handle contact form and show success message
  };

  return (
    <Grid container rowSpacing={1} spacing={2} component={Box}>
      <Grid item xs={12} sm={12} md={6}>
        <Box
          className={classes.formWrapper}
          sx={{ background: '#fff', minHeight: 'auto !important' }}
        >
          <Container maxWidth="md">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3} sx={{ mb: 3 }}>
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    my={2}
                    color="primary"
                    sx={{
                      width: 'fit-content',
                      paddingBottom: 1,
                      fontFamily: 'Raleway !important',
                      fontWeight: 400,
                      borderBottom: '1px solid #023C684D',
                    }}
                  >
                    Any Questions?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    id="name"
                    name="name"
                    rules={{ ...Validations.REQUIRED }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        required
                        id="name"
                        name="name"
                        label="Name"
                        fullWidth
                        type="text"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error?.message : null}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    control={control}
                    id="email"
                    name="email"
                    rules={{ ...Validations.EMAIL_REQUIRED }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        required
                        id="email"
                        fullWidth
                        name="email"
                        label="Email address"
                        type="email"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error?.message : null}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    id="phone"
                    name="phone"
                    control={control}
                    rules={{ validate: matchIsValidTel }}
                    render={({ field, fieldState: { error, invalid } }) => (
                      <MuiTelInput
                        {...field}
                        required
                        fullWidth
                        label="Phone Number"
                        error={!!error || invalid}
                        helperText={
                          error || invalid ? error?.message || 'Invalid Phone Number' : null
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    control={control}
                    id="message"
                    name="message"
                    rules={{ ...Validations.REQUIRED }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        required
                        id="message"
                        name="message"
                        label="Message"
                        fullWidth
                        multiline
                        minRows={5}
                        type="text"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error?.message : null}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button variant="contained" type="submit" disableElevation>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Grid>

      <Grid
        sx={{
          background: `url(${AffiliateAboutFormBanner})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          mt: 1,
          backgroundRepeat: 'no-repeat',
          display: { xs: 'none', md: 'block' },
        }}
        item
        xs={12}
        sm={12}
        md={6}
      />
    </Grid>
  );
};

export default AboutUs;
