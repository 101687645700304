import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, TableCell, TableRow } from '@mui/material';

const TableLoader = ({ rows, cols }) => {
  const rowsArray = [...Array(rows)].map((item, index) => index);
  const colsArray = [...Array(cols)].map((item, index) => index);

  return (
    <>
      {rowsArray.map((r) => (
        <TableRow key={`ldr-${r}`}>
          {colsArray.map((c) => (
            <TableCell key={`ldc-${c}`}>
              <Skeleton variant="text" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

TableLoader.propTypes = {
  rows: PropTypes.number,
  cols: PropTypes.number,
};
TableLoader.defaultProps = {
  rows: 10,
  cols: 5,
};

export default TableLoader;
