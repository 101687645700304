import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#023C68',
    },
    secondary: {
      main: '#E18840',
    },
  },
  typography: {
    fontFamily: 'Lato, system-ui, sans-serif',
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        '&:focus': {
          outline: 'none !important',
        },
        '&.Mui-selected': {
          color: '#0074B5',
          backgroundColor: 'red',
          '&:hover': {
            color: '#0074B5',
            backgroundColor: 'red',
          },
        },
      },
    },
  },
});

export default theme;
