import {
  toggleMainNavbarDrawer,
  openMainDrawer,
  closeMainDrawer,
  toggleSidebarDrawer,
  openSidebarDrawer,
  closeSidebarDrawer,
} from '../reducers/drawerSlice';
import store from '../store';

export const handleMainNavbarDrawerToggle = () => {
  store.dispatch(toggleMainNavbarDrawer());
};

export const handleOpenMainNavbarDrawer = () => {
  store.dispatch(openMainDrawer());
};

export const handleCloseMainNavbarDrawer = () => {
  store.dispatch(closeMainDrawer());
};

export const handleSidebarDrawerToggle = () => {
  store.dispatch(toggleSidebarDrawer());
};

export const handleOpenSidebarDrawer = () => {
  store.dispatch(openSidebarDrawer());
};

export const handleCloseSidebarDrawer = () => {
  store.dispatch(closeSidebarDrawer());
};
