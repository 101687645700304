import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import RoutePaths from './configs/Routes';
import theme from './styles/Theme';
import Toastr from './components/common/Toastr';
import NotFound from './views/NotFound';
import ScrollToTop from './components/common/ScrollToTop';
import RegisterPage from './views/auth/Register';
import LoginPage from './views/auth/Login';
import LogoutPage from './views/auth/Logout';
import ResetPasswordPage from './views/auth/ResetPassword';
import HomePage from './views/Home';
import ManageProfilePage from './views/ManageProfile';
import PaymentsPage from './views/Payments';
import CreativesPage from './views/Creatives';
import SalesPage from './views/Sales';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Toastr />
        <Router>
          <ScrollToTop />
          <Routes>
            <Route exact path={RoutePaths.HOME} element={<HomePage />} />
            <Route exact path={RoutePaths.MANAGE_PROFILE} element={<ManageProfilePage />} />
            <Route exact path={RoutePaths.PAYMENTS} element={<PaymentsPage />} />
            <Route exact path={RoutePaths.CREATIVES} element={<CreativesPage />} />
            <Route exact path={RoutePaths.SALES} element={<SalesPage />} />
            <Route exact path={RoutePaths.SIGN_UP} element={<RegisterPage />} />
            <Route exact path={RoutePaths.LOGIN} element={<LoginPage />} />
            <Route exact path={RoutePaths.RESET_PASSWORD} element={<ResetPasswordPage />} />
            <Route exact path={RoutePaths.LOGOUT} element={<LogoutPage />} />
            <Route exact path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
